import ClearableInput from '@rio-cloud/rio-uikit/components/clearableInput/ClearableInput';
import { useIntl } from 'react-intl';

type SearchFieldProps = {
  onSearch: (value: string) => void;
};

const SearchField = ({ onSearch }: SearchFieldProps) => {
  const intl = useIntl();

  return (
    <div className="form-group padding-x-20 margin-bottom-25">
      <div className="input-group">
        <span className="input-group-addon">
          <span className="rioglyph rioglyph-search" aria-hidden="true" />
        </span>
        <ClearableInput
          placeholder={intl.formatMessage({ id: 'chatPage.searchList.placeholder' })}
          onChange={(value: string) => onSearch(value)}
        />
      </div>
    </div>
  );
};

export default SearchField;
