export const onboardingMessagesEN = {
    'step-0': {
        'messages': {
            'role': 'ai',
            'content': []
        }
    },
    'step-1': {
        'messages': {
            'role': 'ai',
            'content': [
                {
                    'id': 'onb-0',
                    'timestamp': '2024-11-21T06:35:39.224335+00:00',
                    'role': 'human',
                    'threadId': 'onboarding-thread',
                    'content': 'DEMO: Which drivers need support due to an overall rating below 70 on average in the last month and list their ratings?'
                },
                {
                    'id': 'onb-1',
                    'timestamp': '2024-11-21T06:36:09.224354+00:00',
                    'role': 'ai',
                    'threadId': 'onboarding-thread',
                    'content': 'The following drivers need support due to an overall rating below **70** on average over the last month:\n\n' +
                        '```json\n' +
                        '{\n' +
                        '  "table": [\n' +
                        '    { "Driver": "Max Mustermann", "Average Total Rating": 25.0 },\n' +
                        '    { "Driver": "Jan Kowalski", "Average Total Rating": 26.0 },\n' +
                        '    { "Driver": "John Doe", "Average Total Rating": 26.0 },\n' +
                        '    { "Driver": "Morten Menigmand", "Average Total Rating": 27.0 },\n' +
                        '    { "Driver": "Jean Dupont", "Average Total Rating": 44.0 },\n' +
                        '    { "Driver": "Mario Rossi", "Average Total Rating": 44.0 },\n' +
                        '    { "Driver": "Jan Janssen", "Average Total Rating": 45.0 },\n' +
                        '    { "Driver": "Jane Doe", "Average Total Rating": 48.0 }\n' +
                        '  ]\n' +
                        '}\n' +
                        '```\n\n' +
                        'These drivers should be provided with additional support and training to improve their overall performance and reach the target average total rating of **70** or above.\n\n'
                }
            ],
        },
    },
    'step-2': {
        'messages': {
            'role': 'ai',
            'content': [
                {
                    'id': 'onb-2',
                    'timestamp': '2024-11-21T06:36:39.224360+00:00',
                    'role': 'human',
                    'threadId': 'onboarding-thread',
                    'content': 'What is the reason for the low rating of Max Mustermann?'
                },
                {
                    'id': 'onb-3',
                    'timestamp': '2024-11-21T06:37:09.224366+00:00',
                    'role': 'ai',
                    'threadId': 'onboarding-thread',
                    'content': 'The reason for the low rating of **Max Mustermann** in the last month appears to be:\n\n' +
                        '- Low average braking pedal rating of **15.0**\n\n' +
                        'The low braking pedal rating suggests that Max Mustermann had issues with smooth and controlled braking during this time period. These factors likely contributed to the overall low total rating of **25.0** for Max Mustermann in the last month.\n\n'
                }
            ],
        },
    },
    'step-3': {
        'messages': {
            'role': 'ai',
            'content': [
                {
                    'id': 'onb-4',
                    'timestamp': '2024-11-21T06:37:39.224372+00:00',
                    'role': 'human',
                    'threadId': 'onboarding-thread',
                    'content': 'How is the brake rating put together?'
                },
                {
                    'id': 'onb-5',
                    'timestamp': '2024-11-21T06:38:09.224378+00:00',
                    'role': 'ai',
                    'threadId': 'onboarding-thread',
                    'content': 'The **braking rating** is put together as follows:\n\n' +
                        '1. Ratio of Wear-Free Brake to Service Brake:\n' +
                        '    - The rating is based on the ratio of the braked distance using the wear-free brake (e.g. retarder, engine brake) compared to the service brake.\n' +
                        '    - A **ratio of 5:1 or higher** for the wear-free brake results in a **100% braking rating**.\n' +
                        '    - A **ratio of 0.5:1 or lower** for the wear-free brake results in a **0% braking rating**.\n\n' +
                        '2. Percentage of Distance Braked with Service Brake:\n' +
                        '    - The rating also considers the percentage of the total driving distance that is braked using the service brake.\n' +
                        '    - **Less than 1%** of the distance braked with the service brake results in a **100% braking rating**.\n' +
                        '    - **More than 5.5%** of the distance braked with the service brake results in a **0% braking rating**.\n\n' +
                        '3. Overall Braking Rating:\n' +
                        '    - The overall braking rating is the calculated average of the sub-ratings, which include coasting mode, cruise control, accelerator, brakes and overspeed.\n' +
                        '    - The higher the overall value, the better the braking rating.\n\n' +
                        'In summary, the **braking rating** is a composite metric that considers the relative usage of the wear-free brake versus the service brake, as well as the percentage of the total distance braked using the service brake. Achieving a high braking rating requires **minimizing** the use of the service brake and **maximizing** the use of the wear-free braking systems.\n\n' +
                        '---\n\n' +
                        'Based on the Following Documents:\n\n' +
                        '1. [Total_Rating - Page 3 - Part 2](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)\n' +
                        '2. [Manual - Page 450 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=450)\n' +
                        '3. [Manual - Page 71 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=71)\n' +
                        '4. [Logging_csv - Page 15 - Part 7](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=15)\n' +
                        '5. [Logging_csv - Page 16 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n' +
                        '6. [Logging_csv - Page 16 - Part 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n' +
                        '7. [Logging_csv - Page 15 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=15)\n' +
                        '8. [Manual - Page 99 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=99)\n' +
                        '9. [Logging_csv - Page 16 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n' +
                        '10. [Total_Rating - Page 3 - Part 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)'
                }
            ],
        },
    },
    'step-4': {
        'messages': {
            'role': 'ai',
            'content': [
                {
                    'id': 'onb-6',
                    'timestamp': '2024-11-21T06:38:39.224384+00:00',
                    'role': 'human',
                    'threadId': 'onboarding-thread',
                    'content': 'What can my driver Max Mustermann who has a low brake pedal rating do to improve?'
                },
                {
                    'id': 'onb-7',
                    'timestamp': '2024-11-21T06:39:09.224403+00:00',
                    'role': 'ai',
                    'threadId': 'onboarding-thread',
                    'content': 'Here are some suggestions for **Max Mustermann** and other drivers with a low brake pedal rating to improve:\n\n' +
                        '1. Increase Use of Wear-Free Brakes:\n' +
                        '    - A high ratio of braking distance using the wear-free brakes (e.g. retarder, engine brake) compared to the service brake is key for a good braking rating.\n' +
                        '    - Drivers should be encouraged to utilize the wear-free braking systems as much as possible, rather than relying heavily on the service brake.\n\n' +
                        '2. Reduce Service Brake Usage:\n' +
                        '    - The braking rating penalizes drivers who use the service brake for more than 1% of the total driving distance.\n' +
                        '    - Drivers should aim to minimize their use of the service brake by anticipating stops, coasting, and leveraging the wear-free braking systems.\n\n' +
                        '3. Smooth, Controlled Braking:\n' +
                        '    - Progressive braking is preferred over abrupt use of the service brake.\n' +
                        '    - Drivers should practice smooth, controlled braking techniques to reduce wear on the braking components and improve the braking rating.\n\n' +
                        '4. Utilize Assistive Technologies:\n' +
                        '    - The overall braking rating is the calculated average of the sub-ratings, which include coasting mode, cruise control, accelerator, brakes and overspeed.\n' +
                        '    - Encouraging the use of these technologies can indirectly improve the braking rating.\n\n' +
                        '5. Anticipatory Driving:\n' +
                        '    - By driving in a more anticipatory manner and releasing the accelerator pedal early, drivers can minimize the need to use the service brake.\n' +
                        '    - This **coasting** behavior helps improve the braking rating.\n\n' +
                        'The key for drivers is to focus on maximizing the use of wear-free braking systems, minimizing service brake usage, and adopting smooth, controlled braking techniques. Proper training and the utilization of available vehicle technologies can help drivers achieve a better braking rating.\n\n' +
                        '---\n\n' +
                        'Based on the Following Documents:\n\n' +
                        '1. [Manual - Page 450 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=450)\n' +
                        '2. [Manual - Page 71 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=71)\n' +
                        '3. [Manual - Page 99 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=99)\n' +
                        '4. [Logging_csv - Page 13 - Part 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=13)\n' +
                        '5. [Total_Rating - Page 5 - Part 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=5)\n' +
                        '6. [Total_Rating - Page 2 - Part 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=2)\n' +
                        '7. [Logging_csv - Page 16 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n' +
                        '8. [Total_Rating - Page 3 - Part 2](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)\n' +
                        '9. [Total_Rating - Page 7 - Part 2](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=7)\n' +
                        '10. [Total_Rating - Page 3 - Part 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)'
                }
            ],
        },
    }
};

export const onboardingMessagesDE = {
    'step-0': {
        'messages': {
            'role': 'ai',
            'content': []
        }
    },
    'step-1': {
        'messages': {
            'role': 'ai',
            'content': [
                {
                    'id': 'onb-0',
                    'timestamp': '2024-11-21T06:35:39.224335+00:00',
                    'role': 'human',
                    'threadId': 'onboarding-thread',
                    'content': 'DEMO: Welche Fahrer benötigen Unterstützung aufgrund einer Gesamtbewertung unter 70 im Durchschnitt im vergangenen Monat und wie lauten ihre Bewertungen?'
                },
                {
                    'id': 'onb-1',
                    'timestamp': '2024-11-21T06:36:09.224354+00:00',
                    'role': 'ai',
                    'threadId': 'onboarding-thread',
                    'content': 'Die folgenden Fahrer benötigen Unterstützung aufgrund einer durchschnittlichen Gesamtbewertung von unter **70** im letzten Monat:\n\n' +
                        '| Fahrer               | Durchschnittliche Gesamtbewertung |\n' +
                        '|----------------------|-----------------------------------|\n' +
                        '| Max Mustermann       | **25.0**                          |\n' +
                        '| Jan Kowalski         | **26.0**                          |\n' +
                        '| John Doe             | **26.0**                          |\n' +
                        '| Morten Menigmand     | **27.0**                          |\n' +
                        '| Jean Dupont          | **44.0**                          |\n' +
                        '| Mario Rossi          | **44.0**                          |\n' +
                        '| Jan Janssen          | **45.0**                          |\n' +
                        '| Jane Doe             | **48.0**                          |\n\n' +
                        'Diese Fahrer sollten zusätzliche Unterstützung und Schulungen erhalten, um ihre Gesamtleistung zu verbessern und die angestrebte durchschnittliche Gesamtbewertung von **70** oder mehr zu erreichen.\n\n'
                }
            ],
        },
    },
    'step-2': {
        'messages': {
            'role': 'ai',
            'content': [
                {
                    'id': 'onb-2',
                    'timestamp': '2024-11-21T06:36:39.224360+00:00',
                    'role': 'human',
                    'threadId': 'onboarding-thread',
                    'content': 'Was ist der Grund für die niedrige Bewertung von Max Mustermann?'
                },
                {
                    'id': 'onb-3',
                    'timestamp': '2024-11-21T06:37:09.224366+00:00',
                    'role': 'ai',
                    'threadId': 'onboarding-thread',
                    'content': 'Der Grund für die niedrige Bewertung von **Max Mustermann       ** im letzten Monat scheint zu sein:\n\n' +
                        '- Niedrige durchschnittliche Bremspedal-Bewertung von **15.0**\n\n' +
                        'Die niedrige Bremspedalbewertung deutet darauf hin, dass Max Mustermann        in diesem Zeitraum Probleme mit dem gleichmäßigen und kontrollierten Bremsen hatte. Diese Faktoren haben wahrscheinlich zu der niedrigen Gesamtbewertung von 25,0 für Max Mustermann        im letzten Monat beigetragen.\n\n'
                }
            ],
        },
    },
    'step-3': {
        'messages': {
            'role': 'ai',
            'content': [
                {
                    'id': 'onb-4',
                    'timestamp': '2024-11-21T06:37:39.224372+00:00',
                    'role': 'human',
                    'threadId': 'onboarding-thread',
                    'content': 'Wie setzt sich die Bremsleistung zusammen?'
                },
                {
                    'id': 'onb-5',
                    'timestamp': '2024-11-21T06:38:09.224378+00:00',
                    'role': 'ai',
                    'threadId': 'onboarding-thread',
                    'content': 'Die **Bremsleistung** setzt sich wie folgt zusammen:\n\n' +
                        '1. Verhältnis von verschleißfreier Bremse zu Betriebsbremse:\n' +
                        '    - Das Rating basiert auf dem Verhältnis der Bremsstrecke, die mit der verschleißfreien Bremse (z.B. Retarder, Motorbremse) im Vergleich zur Betriebsbremse zurückgelegt wird.\n' +
                        '    - Ein **Verhältnis von 5:1 oder höher** für die verschleißfreie Bremse ergibt eine **100%ige Bremsleistung**.\n' +
                        '    - Ein **Verhältnis von 0,5:1 oder niedriger** für die verschleißfreie Bremse ergibt eine **0%ige Bremsleistung**.\n\n' +
                        '2. Anteil der Strecke, der mit der Betriebsbremse abgebremst wird:\n' +
                        '    - Die Bewertung berücksichtigt auch den Anteil der Gesamtfahrstrecke, der mit der Betriebsbremse abgebremst wird.\n' +
                        '    - **Weniger als 1%** der Strecke, die mit der Betriebsbremse abgebremst wird, ergibt eine **100%ige Bremsleistung**.\n' +
                        '    - **Mehr als 5,5%** der Strecke, die mit der Betriebsbremse abgebremst wird, ergibt eine **0%ige Bremsleistung**.\n\n' +
                        '3. Gesamtbewertung der Bremsleistung:\n' +
                        '    - Die Gesamtbewertung der Bremsleistung ist der berechnete Durchschnittswert aus Teilbewertungen wie Gleitphase, Tempomat, Gaspedal, Bremsen und Geschwindigkeitsüberschreitung.\n' +
                        '    - Je höher der Gesamtwert, desto besser ist die Bremsleistung.\n\n' +
                        'Zusammengefasst ist **die Bremsleistung** eine Kennzahl, die das Verhältnis der Nutzung von verschleißfreier Bremse zu Betriebsbremse sowie den Anteil der Gesamtstrecke, der mit der Betriebsbremse abgebremst wird, berücksichtigt. Eine hohe Bremsleistung erfordert die Minimierung des Einsatzes der Betriebsbremse und die Maximierung der Nutzung von verschleißfreien Bremssystemen.\n\n' +
                        '---\n\n' +
                        'Basierend auf den folgenden Dokumenten wurde die Antwort generiert (nach Relevanz sortiert):\n\n' +
                        '1. [Webinar_2 - Seite 3 - Teil 14](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)\n' +
                        '2. [Webinar_3 - Seite 1 - Teil 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=450)\n' +
                        '3. [Webinar_3 - Seite 2 - Teil 18](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=71)\n' +
                        '4. [Webinar_2 - Seite 2 - Teil 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=15)\n' +
                        '5. [Webinar_1 - Seite 1 - Teil 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n' +
                        '6. [Logging_csv - Seite 13 - Teil 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n' +
                        '7. [Webinar_3 - Seite 2 - Teil 12](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=15)\n' +
                        '8. [Webinar_2 - Seite 2 - Teil 21](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=99)\n' +
                        '9. [Webinar_1 - Seite 2 - Teil 17](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n' +
                        '10. [Webinar_3 - Seite 3 - Teil 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)'
                }
            ],
        },
    },
    'step-4': {
        'messages': {
            'role': 'ai',
            'content': [
                {
                    'id': 'onb-6',
                    'timestamp': '2024-11-21T06:38:39.224384+00:00',
                    'role': 'human',
                    'threadId': 'onboarding-thread',
                    'content': 'Was kann mein Fahrer Max Mustermann, der ein niedriges Bremsrating hat, tun, um sich zu verbessern?'
                },
                {
                    'id': 'onb-7',
                    'timestamp': '2024-11-21T06:39:09.224403+00:00',
                    'role': 'ai',
                    'threadId': 'onboarding-thread',
                    'content': '**Max Mustermann       ** oder andere Fahrer mit einem niedrigen Bremsrating können Folgendes tun, um sich zu verbessern:\n\n' +
                        '1. Erhöhung der Nutzung von verschleißfreien Bremsen:\n' +
                        '    - Ein hohes Verhältnis der Bremsstrecke mit den verschleißfreien Bremsen (z.B. Retarder, Motorbremse) im Vergleich zur Betriebsbremse ist entscheidend für ein gutes Bremsrating.\n' +
                        '    - Fahrer sollten dazu angehalten werden, die verschleißfreien Bremssysteme so weit wie möglich zu nutzen, anstatt sich stark auf die Betriebsbremse zu verlassen.\n\n' +
                        '2. Reduzierung der Nutzung der Betriebsbremse:\n' +
                        '    - Das Bremsrating bestraft Fahrer, die die Betriebsbremse für mehr als 1% der Gesamtfahrstrecke verwenden.\n' +
                        '    - Fahrer sollten darauf achten, die Nutzung der Betriebsbremse zu minimieren, indem sie Stopps antizipieren, ausrollen lassen und die verschleißfreien Bremssysteme nutzen.\n\n' +
                        '3. Sanftes und kontrolliertes Bremsen:\n' +
                        '    - Ein sanftes, dosiertes Bremsen gegenüber abruptem Einsatz der Betriebsbremse wird bevorzugt.\n' +
                        '    - Fahrer sollten Techniken für weiches, kontrolliertes Bremsen üben, um den Verschleiß der Bremskomponenten zu reduzieren und das Bremsrating zu verbessern.\n\n' +
                        '4. Nutzung von Assistenzsystemen:\n' +
                        '    - Funktionen wie Tempomat und Geschwindigkeitsbegrenzer können Fahrer dabei unterstützen, angemessene Geschwindigkeiten beizubehalten und den Bedarf für starkes Bremsen zu reduzieren.\n' +
                        '    - Die Förderung des Einsatzes dieser Technologien kann das Bremsrating indirekt verbessern.\n\n' +
                        '5. Vorausschauendes Fahren:\n' +
                        '    - Durch vorausschauendes Fahren und frühzeitiges Lösen des Gaspedals können Fahrer die Notwendigkeit zum Einsatz der Betriebsbremse minimieren.\n' +
                        '    - Dieses **Ausrollen-Lassen** trägt zur Verbesserung des Bremsratings bei.\n\n' +
                        '-Der Schlüssel für Fahrer ist es, den Einsatz von verschleißfreien Bremssystemen zu maximieren, die Nutzung der Betriebsbremse zu minimieren und sanfte, kontrollierte Bremstechniken anzuwenden. Entsprechendes Training und der Einsatz der verfügbaren Fahrzeugtechnologien können Fahrer dabei helfen, ein besseres Bremsrating zu erreichen.\n\n' +
                        '---\n\n' +
                        'Basierend auf den folgenden Dokumenten wurde die Antwort generiert (nach Relevanz sortiert):\n\n' +
                        '1. [Webinar_1 - Seite 1 - Teil 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_1.pdf#page=1)\n' +
                        '2. [Webinar_3 - Seite 2 - Teil 13](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_3.pdf#page=2)\n' +
                        '3. [FAQ_RIO - Seite 2 - Teil 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/FAQ_RIO.pdf#page=2)\n' +
                        '4. [Webinar_3 - Seite 3 - Teil 13](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_3.pdf#page=3)\n' +
                        '5. [Webinar_3 - Seite 3 - Teil 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_3.pdf#page=3)\n' +
                        '6. [Webinar_1 - Seite 1 - Teil 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_1.pdf#page=1)\n' +
                        '7. [Webinar_2 - Seite 3 - Teil 13](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_2.pdf#page=3)\n' +
                        '8. [Webinar_1 - Seite 3 - Teil 18](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_1.pdf#page=3)\n' +
                        '9. [Webinar_2 - Seite 3 - Teil 14](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_2.pdf#page=3)\n' +
                        '10. [Webinar_2 - Seite 2 - Teil 21](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_2.pdf#page=2)'
                }
            ],
        },
    }
};