import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const cardContent = [
  {
    title: 'getStartedPage.overviewCards.poweredByAI.label',
    description: 'getStartedPage.overviewCards.poweredByAI.description',
    iconClass: 'rioglyph-robot',
  },
  {
    title: 'getStartedPage.overviewCards.streamliningFleetManagement.label',
    description: 'getStartedPage.overviewCards.streamliningFleetManagement.description',
    iconClass: 'rioglyph-truck-baseline',
  },
  {
    title: 'getStartedPage.overviewCards.howItHelpsYou.label',
    description: 'getStartedPage.overviewCards.howItHelpsYou.description',
    iconClass: 'rioglyph-stats',
  },
];

const OverviewCards = () => (
  <CardsContainer>
    {cardContent.map((card, index) => (
      <Card key={index}>
        <IconContainer>
          <span className={`rioglyph ${card.iconClass}`} />
        </IconContainer>
        <CardTitle>
          <FormattedMessage id={card.title} />
        </CardTitle>
        <CardDescription>
          <FormattedMessage id={card.description} />
        </CardDescription>
      </Card>
    ))}
  </CardsContainer>
);

export default OverviewCards;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  margin-bottom: 3rem;
  gap: 2rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(var(--color-black), 0.1);

  @media (max-width: 48rem) {
    width: 90%;
  }
`;

const IconContainer = styled.div`
  font-size: 6rem;
  color: var(--brand-default);
  margin-bottom: 1.5rem;

  @media (max-width: 64rem) {
    font-size: 4rem;
  }
`;

const CardTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--gray-darkest);
`;

const CardDescription = styled.p`
  font-size: 1.3rem;
  text-align: center;
  color: var(--gray-darker);
  line-height: 1.6;
  margin: 0;
`;
