import ApplicationLayout from '@rio-cloud/rio-uikit/components/applicationLayout/ApplicationLayout';
import AssetTree from '@rio-cloud/rio-uikit/components/assetTree/AssetTree';
import { useIntl } from 'react-intl';
import TreeCategory from '@rio-cloud/rio-uikit/components/assetTree/TreeCategory';
import { useEffect, useRef, useState } from 'react';
import { closeSidebar, selectIsSidebarOpen, toggleSidebar } from '@/store/sidebar/sidebarSlice';
import { selectCurrentLayout, setCurrentLayout } from '@/store/layout/layoutSlice';
import { LayoutItem, sidebarToContent } from '@/store/layout/sidebarToContent';
import { componentMap } from '@/components/pages/ChatPage/ChatPage';
import { setCurrentPage } from '@/store/about/aboutSlice';
import { Steps } from '@/store/onboarding/onboardingSlice';
import { gaPush, SIDEBAR_ACTIONS, SIDEBAR_CATEGORY } from '@/configuration/setup/googleAnalytics';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';

type ChatAssetTreeProps = {
  handleSend: (content: string, isOnboarding?: boolean, nextStepIndex?: Steps) => Promise<void>;
  isLoading: boolean;
};

const ChatAssetTree = ({ isLoading, handleSend }: ChatAssetTreeProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isTreeOpen = useAppSelector(selectIsSidebarOpen);
  const activeSidebarItem = useAppSelector(selectCurrentLayout);
  const sidebarRef = useRef(null);
  const [isWide, setIsWide] = useState(false);

  useEffect(() => {
    const checkSidebarWidth = () => {
      if (sidebarRef.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const sidebarWidth = sidebarRef.current.offsetWidth;
        const windowWidth = window.innerWidth;
        setIsWide(sidebarWidth / windowWidth > 0.6);
      }
    };

    checkSidebarWidth();
    window.addEventListener('resize', checkSidebarWidth);
    return () => window.removeEventListener('resize', checkSidebarWidth);
  }, [isTreeOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (isWide && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      dispatch(closeSidebar());
    }
  };

  useEffect(() => {
    if (isTreeOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isTreeOpen, isWide]);

  const handleToggleTree = () => dispatch(toggleSidebar());
  const handleOnChange = (selectCategoryId: string) => {
    dispatch(setCurrentLayout(selectCategoryId as LayoutItem));
    if (activeSidebarItem !== 'about') {
      dispatch(setCurrentPage('page1'));
    }
    gaPush({
      category: SIDEBAR_CATEGORY,
      action: SIDEBAR_ACTIONS.SELECT_CATEGORY,
      label: `Category ID: ${selectCategoryId}`,
    });
  };

  return (
    <ApplicationLayout.Sidebar>
      {activeSidebarItem ? (
        <AssetTree
          minWidth={300}
          maxWidth={400}
          currentCategoryId={activeSidebarItem}
          onCategoryChange={handleOnChange}
          isOpen={isTreeOpen}
          onToggleTree={handleToggleTree}
          useOffscreen
          ref={sidebarRef}
        >
          {Object.entries(sidebarToContent).map(([key, item]) => {
            const SidebarComponent = componentMap[key as LayoutItem]?.sidebarComponent;

            return (
              <TreeCategory
                key={`${key}-sidebar`}
                id={key}
                label={intl.formatMessage({ id: item.translation })}
                icon={item.icon}
              >
                {SidebarComponent && <SidebarComponent handleSend={handleSend} isLoading={isLoading} />}
              </TreeCategory>
            );
          })}
        </AssetTree>
      ) : null}
    </ApplicationLayout.Sidebar>
  );
};

export default ChatAssetTree;
