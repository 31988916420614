import { useAppSelector } from '@/configuration/setup/hooks';
import { selectStatuses } from '@/store/processingStatus/processingStatusSlice';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { useIntl } from 'react-intl';

const ProcessingStatuses = () => {
  const intl = useIntl();
  const steps = useAppSelector(selectStatuses);
  
  return (
    <div className="min-height-200 margin-25">
      {steps.length > 0 ? steps.map(
        (step, index) =>
          step.visible && (
            <div key={index} className="display-flex align-items-center margin-bottom-10">
              {step.completed ? (
                <span className="rioglyph rioglyph-ok-sign text-color-primary text-size-18" />
              ) : (
                <Spinner />
              )}
              <span className="margin-left-10">
                {step.type === 'interpolation'
                ? intl.formatMessage({id: `chatPage.processIndicator.${step.message.split("|")[0]}`}, {question: step.message.split("|")[1]}) : intl.formatMessage({id: `chatPage.processIndicator.${step.message}`})
                }
              </span>
            </div>
          ),
      ) : null}
    </div>
  );
};

export default ProcessingStatuses;
