import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface ChatFeedbackProps {
  iconName: string;
  iconClass: string;
  tooltip: string;
  additionalClass?: string;
  isActive: boolean;
  clickAction: (icon: string, message: string) => void;
  dataTestId?: string;
}

export const ChatFeedbackOptions = ({
  iconName,
  iconClass,
  tooltip,
  additionalClass,
  isActive,
  clickAction,
  dataTestId,
}: ChatFeedbackProps) => {
  const handleClick = (icon: string) => clickAction(icon, tooltip);

  return (
    <FeedbackOptionWrapper className={`${additionalClass || ''} ${isActive ? 'active' : ''}`}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" allowOnTouch>
            <FormattedMessage id={tooltip} />
          </Tooltip>
        }
      >
        <span className={`rioglyph ${iconClass}`} onClick={() => handleClick(iconName)} data-testid={dataTestId} />
      </OverlayTrigger>
    </FeedbackOptionWrapper>
  );
};

const FeedbackOptionWrapper = styled.div`
  font-size: 2.5rem;
  cursor: pointer;
  color: var(--gray-light);
  &:hover {
    color: var(--brand-primary);
  }
  &.active {
    color: var(--brand-primary);
  }
`;
