import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type ProcessingStatus = {
    id: string;
    message: string;
    completed: boolean;
    visible: boolean;
    threadId: string | undefined;
    type: string | undefined;
};

export type ConnectionStatus = 'connected' | 'disconnected';

type ProcessingStatusState = {
    statuses: ProcessingStatus[];
    connectionStatus: ConnectionStatus;
    processingThreadId: string | undefined;
};

const initialStatus: ProcessingStatus = {
    id: 'initial',
    message: 'label',
    completed: false,
    threadId: undefined,
    visible: true,
    type: undefined,
};

const initialState: ProcessingStatusState = {
    statuses: [initialStatus],
    connectionStatus: 'disconnected',
    processingThreadId: undefined
};

const processingStatusSlice = createSlice({
    name: 'processingStatus',
    initialState,
    reducers: {
        setConnectionStatus(state, action: PayloadAction<{ status: 'connected' | 'disconnected' }>) {
            state.connectionStatus = action.payload.status;
        },
        addStatus(state, action: PayloadAction<ProcessingStatus>) {
            const existingStatus = state.statuses.find(status => status.id === action.payload.id);
            if (!existingStatus) {
                state.statuses.forEach(status => {
                    status.completed = true;
                });
                state.statuses.push(action.payload);
            }
        },
        clearStatuses(state) {
            state.statuses = [initialStatus];
        }
    },
});

export const { setConnectionStatus, addStatus, clearStatuses } = processingStatusSlice.actions;

export default processingStatusSlice.reducer;

export const selectConnectionStatus = (state: RootState) => state.processingStatus.connectionStatus;

export const selectStatuses = (state: RootState) => state.processingStatus.statuses;

export const selectProcessingThreadId = (state: RootState) => state.processingStatus.processingThreadId;

export const selectStatusById = (id: string) => (state: RootState) =>
    state.processingStatus.statuses.find((status) => status.id === id);
