import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from '@rio-cloud/rio-uikit/Button';
import Highlighter from 'react-highlight-words';
import { useEffect, useState } from 'react';
import Divider from '@rio-cloud/rio-uikit/components/divider/Divider';
import { useMediaQuery } from 'react-responsive';
import { addQuestions, FAQsItem, filterAllFAQs, selectAllFAQs } from '@/store/faq/faqSlice';
import { getMockFAQs } from '../../../../mocks/mock.faqs';
import getDatesToTranslations from '@/utils/getDatesToTranslations/getDatesToTranslations';
import {
  removeOnboardingThread,
  resetActiveThread,
  selectActiveThread,
  selectIsThreadLoading,
} from '@/store/thread/threadSlice';
import { disableOnboardingMode, Steps } from '@/store/onboarding/onboardingSlice';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import SearchField from '@/components/atom/SearchField/SearchField';
import { closeSidebar } from '@/store/sidebar/sidebarSlice';
import QuestionsAssetTreeSummary from '@/components/atom/QuestionsAssetTreeSummary/QuestionsAssetTreeSummary';

type ExampleQuestionsListProps = {
  isLoading?: boolean;
  handleSend?: (content: string, isOnboarding?: boolean, nextStepIndex?: Steps) => Promise<void>;
};

const ExampleQuestionsList = ({ isLoading, handleSend }: ExampleQuestionsListProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const activeThread = useAppSelector(selectActiveThread);
  const localId = activeThread ? activeThread.id : null;
  const isThreadsLoading = useAppSelector(selectIsThreadLoading(localId));
  const allFAQs = useAppSelector(selectAllFAQs);
  const allFilteredFAQs = useAppSelector(filterAllFAQs);
  const dates = getDatesToTranslations();

  const mappedFAQs = getMockFAQs().map((faq) => ({
    id: faq.id,
    name: intl.formatMessage({ id: faq.translation }, dates),
    icon: faq.icon,
  }));
  const isSmallScreen = useMediaQuery({ query: '(max-width: 47.5rem)' });

  if (allFAQs.length === 0) {
    dispatch(addQuestions(mappedFAQs));
  }

  const [faqsList, setFaqsList] = useState(allFAQs);
  const [searchValue, setSearchValue] = useState('');
  const [filterFaqsList, setFilterFaqsList] = useState<string>('');

  const filterFaqs = (filter: string | null, allFAQs: FAQsItem[]) =>
    filter ? allFAQs.filter((item) => item.icon === filter) : allFAQs;

  useEffect(() => {
    const filteredFAQs = allFAQs.filter(
      (item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()) &&
        (filterFaqsList ? item.icon === filterFaqsList : true),
    );
    setFaqsList(filteredFAQs);
  }, [searchValue]);

  const handleSelection = async (item: FAQsItem) => {
    const question = mappedFAQs.find((q) => q.id === item.id);
    if (isLoading || isThreadsLoading || !question) {
      return;
    }
    if (handleSend) {
      handleSend(question.name);
    }
    if (isSmallScreen) {
      dispatch(closeSidebar());
    }
  };

  const handleOnClick = () => {
    dispatch(resetActiveThread());
    dispatch(disableOnboardingMode());
    dispatch(removeOnboardingThread());
  };

  useEffect(() => {
    const filteredFAQs = filterFaqs(filterFaqsList, allFAQs);
    setFaqsList(filteredFAQs);
  }, [allFAQs, filterFaqsList]);

  return (
    <div className="padding-y-20">
      <div className="display-flex justify-content-start margin-bottom-20 padding-x-20 width-100pct">
        <Button
          className="width-100pct"
          bsStyle="primary"
          iconName="rioglyph rioglyph-plus-light"
          disabled={isLoading}
          onClick={handleOnClick}
        >
          <FormattedMessage id={'chatPage.chatThreadList.threadDeletion.addNewChat.label'} />
        </Button>
      </div>
      <p className="margin-x-20 text-medium text-size-14 margin-bottom-20">
        {intl.formatMessage({ id: 'chatPage.sidebar.faq.label' })}
      </p>
      <SearchField onSearch={(value) => setSearchValue(value)} />
      <QuestionsAssetTreeSummary
        faqsList={allFilteredFAQs}
        activeIcon={filterFaqsList}
        onFilterSelect={(icon) => setFilterFaqsList(icon)}
      />
      <div>
        <ul className="overflow-auto list-group padding-0">
          <StyledListItemManager>
            {faqsList.map((faq, index) => (
              <div key={`faq-list-wrapper-${index}`}>
                <Divider spacing={5} key={`faq-list-divider-${index}`} className="padding-0" />
                <StyledListItem key={`faq-list-item-${index}`} onClick={() => handleSelection(faq)}>
                  <StyledImg>
                    <span className={`rioglyph rioglyph-${faq.icon}`} />
                  </StyledImg>
                  <Highlighter
                    key={faq.id}
                    highlightStyle={highlightStyle}
                    searchWords={[searchValue]}
                    autoEscape={false}
                    textToHighlight={faq.name}
                  />
                </StyledListItem>
              </div>
            ))}
          </StyledListItemManager>
        </ul>
      </div>
    </div>
  );
};

export default ExampleQuestionsList;

const StyledListItemManager = styled.div`
  border-radius: 4px;
  padding: 0;
  transition: background-color 0.3s ease;
`;

const StyledListItem = styled.li`
  display: flex;
  width: 100%;
  gap: 0.6rem;
  align-items: start;
  padding: 1.6rem 1.2rem;
  cursor: pointer;
  font-size: clamp(1rem, 1.2rem, 1.4rem);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: var(--gray-lightest);
  }
`;

const StyledImg = styled.span`
  position: relative;
`;

const highlightStyle = {
  backgroundColor: 'var(--color-highlight-lighter)',
  padding: '0px',
};
