import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dialogReducer from './dialog/dialogSlice';
import appReducer from './app/appSlice';
import userReducer from './user/userSlice';
import chatReducer from './chat/chat.slice';
import feedbackReducer from './feedback/feedbackSlice';
import threadReducer from './thread/threadSlice';
import onboardingReducer from './onboarding/onboardingSlice';
import sidebarReducer from './sidebar/sidebarSlice';
import faqReducer from './faq/faqSlice';
import aboutReducer from './about/aboutSlice';
import layoutReducer from './layout/layoutSlice';
import tipsReducer from './tips/tipsSlice';
import processingStatusReducer from './processingStatus/processingStatusSlice';
import sessionExpiryMiddleware from './middleware/sessionExpiryMiddleware';
import forbiddenMiddleware from './middleware/forbiddenMiddleware';
import gatewayTimeoutMiddleware from './middleware/gatewayMiddleware';
import { userApi } from '@/services/userApi/userApi';
import { chatApi } from '@/services/chatApi/chatApi';
import { threadApi } from '@/services/threadApi/threadApi';
import { feedbackApi } from '@/services/feedbackApi/feedback';
import { cookieApi } from '@/services/cookieApi/cookieApi';

import languageMiddleware from '@/configuration/lang/languageMiddleware';
import tokenReducer from '@/configuration/tokenHandling/tokenSlice';
import langReducer from '@/configuration/lang/langSlice';
import loginReducer from '@/configuration/login/loginSlice';
import configReducer from '@/configuration/setup/configSlice';

export const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [threadApi.reducerPath]: threadApi.reducer,
  [cookieApi.reducerPath]: cookieApi.reducer,
  config: configReducer,
  lang: langReducer,
  app: appReducer,
  user: userReducer,
  chat: chatReducer,
  login: loginReducer,
  tokenHandling: tokenReducer,
  feedback: feedbackReducer,
  thread: threadReducer,
  dialog: dialogReducer,
  onboarding: onboardingReducer,
  sidebar: sidebarReducer,
  faq: faqReducer,
  about: aboutReducer,
  layout: layoutReducer,
  tips: tipsReducer,
  processingStatus: processingStatusReducer,
});

export const rootMiddleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleware().concat(
    userApi.middleware,
    chatApi.middleware,
    feedbackApi.middleware,
    threadApi.middleware,
    cookieApi.middleware,
    languageMiddleware,
    sessionExpiryMiddleware,
    forbiddenMiddleware,
    gatewayTimeoutMiddleware,
  );

export const storeInit = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: rootMiddleware,
    preloadedState,
  });

export const reduxStore = storeInit();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof storeInit>;
export type RootDispatch = AppStore['dispatch'];
