import React, { Fragment, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import TableActionBar from '@/components/organism/TableActionBar/TableActionBar';
import LimitedList from '../LimitedList/LimitedList';
import CardDropdown from '../CardDropdown/CardDropdown';
import { Components } from 'react-markdown';
import Table from '../Table/Table';

const showCard = (
  columnCount: number,
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean,
  isLargeDesktop: boolean,
) => {
  return (
    isMobile || (isTablet && columnCount > 4) || (isDesktop && columnCount > 10) || (isLargeDesktop && columnCount > 20)
  );
};

const getColumnCount = (children: React.ReactNode): number => {
  return React.Children.toArray(children).reduce((count, child) => {
    if (React.isValidElement(child) && child.props.children) {
      return Math.max(count, React.Children.count(child.props.children));
    }
    return count;
  }, 0);
};

const MarkdownRenderers = (message: string, showActions: boolean): Components => {
  let headers: string[] = [];

  return {
    pre: ({ children }: PropsWithChildren) => <Fragment>{children}</Fragment>,
    code: ({ className, children }: PropsWithChildren<{ className?: string }>) => {
      const isJson = className?.includes('language-json');
      if (isJson) {
        try {
          if (!children) {
            throw new Error('No JSON content found');
          }
          const jsonString = Array.isArray(children) ? children.join('') : children.toString();
          const jsonData = JSON.parse(jsonString);

          if (jsonData.table && Array.isArray(jsonData.table)) {
            return <Table data={jsonData.table} message={message} showActions={showActions} />;
          }

          return <Table data={jsonData} message={message} showActions={showActions} />;
        } catch (error) {
          console.error('Failed to parse JSON:', error);
          return <pre className={className}>{children}</pre>;
        }
      }
      return <pre className={className}>{children}</pre>;
    },
    a: ({ href, children }: PropsWithChildren<{ href: string }>) => (
      <span className="text-capitalize">
        <a href={href} target="_blank" rel="noopener noreferrer" className="text-color-primary">
          <span className="rioglyph rioglyph-files" aria-hidden="true" />
          <span className="text-size-12-xs text-size-14-ls">{children}</span>
        </a>
      </span>
    ),
    p: ({ children }: PropsWithChildren) => <div className="padding-y-5">{children}</div>,
    ul: ({ children }: PropsWithChildren) => <LimitedList>{children}</LimitedList>,
    ol: ({ children }: PropsWithChildren) => <LimitedList>{children}</LimitedList>,
    li: ({ children }: PropsWithChildren) => <li className="margin-left-20-ls padding-0">{children}</li>,
    table: ({ children }: PropsWithChildren) => {
      headers = [];
      return (
        <div className="width-100pct">
          <TableContainer className="table-responsive">
            <StyledTable className="table table-head-filled width-100pct">{children}</StyledTable>
          </TableContainer>
          <TableActionBar message={message} />
        </div>
      );
    },
    th: ({ children }: PropsWithChildren) => {
      const childText = React.Children.toArray(children).join('');
      if (!headers.includes(childText)) {
        headers.push(childText);
      }
      return <StyledTh>{children}</StyledTh>;
    },
    td: ({ children }: PropsWithChildren) => {
      return <StyledTd>{children}</StyledTd>;
    },
    tr: ({ children }: PropsWithChildren) => {
      return (
        <StyledTr>{React.Children.map(children, (child, index) => React.cloneElement(child, { index }))}</StyledTr>
      );
    },
    tbody: ({ children }: PropsWithChildren) => {
      const columnCount = getColumnCount(children);
      const isMobile = useMediaQuery({ maxWidth: '48rem' });
      const isTablet = useMediaQuery({ minWidth: '48rem', maxWidth: '64rem' });
      const isDesktop = useMediaQuery({ minWidth: '64rem', maxWidth: '150rem' });
      const isLargeDesktop = useMediaQuery({ minWidth: '150rem' });
      const showCardFlag = showCard(columnCount, isMobile, isTablet, isDesktop, isLargeDesktop);

      return showCardFlag ? <CardDropdown headers={headers}>{children}</CardDropdown> : <tbody>{children}</tbody>;
    },
    thead: ({ children }: PropsWithChildren) => {
      const columnCount = getColumnCount(children);
      const isMobile = useMediaQuery({ maxWidth: '48rem' });
      const isTablet = useMediaQuery({ minWidth: '48rem', maxWidth: '64rem' });
      const isDesktop = useMediaQuery({ minWidth: '64rem', maxWidth: '150rem' });
      const isLargeDesktop = useMediaQuery({ minWidth: '150rem' });
      const showCardFlag = showCard(columnCount, isMobile, isTablet, isDesktop, isLargeDesktop);

      return <StyledThead show={!showCardFlag}>{children}</StyledThead>;
    },
  };
};

export default MarkdownRenderers;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border: none;
  display: block;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  word-break: break-word;
  white-space: nowrap;
`;

const StyledTh = styled.th`
  word-break: break-word;
  white-space: normal;
  min-width: 10rem;
  overflow-wrap: break-word;
  hyphens: auto;
  word-wrap: break-word;
  white-space: pre-wrap !important;
`;

const StyledTd = styled.td`
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap !important;

  @media (max-width: 64rem) {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: -1px;
    position: relative;
    padding-left: 50%;

    &:before {
      content: attr(data-label);
      position: absolute;
      left: 10px;
      width: calc(50% - 20px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      padding-right: 10px;
    }

    span {
      display: block;
      padding-left: 10px;
    }
  }
`;

const StyledTr = styled.tr`
  min-width: 250px;
  flex: 1 1;
  @media (max-width: 64rem) {
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
  }
`;

const StyledThead = styled.thead<{ show: boolean }>`
  display: ${({ show }) => (!show ? 'none' : 'table-header-group')};
`;
