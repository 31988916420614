import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import ServiceCard from '../ServiceCard/ServiceCard';
import OnboardingServiceCard from '../OnboardingServiceCard/OnboardingServiceCard';
import { Steps, selectIsOnboardingMode, resetStep, selectIsDemoFlowEnabled } from '@/store/onboarding/onboardingSlice';
import { selectActiveThread } from '@/store/thread/threadSlice';
import { useIsDemoThread } from '@/hooks/useIsDemoThread';
import { openSidebar } from '@/store/sidebar/sidebarSlice';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import getDatesToTranslations from '@/utils/getDatesToTranslations/getDatesToTranslations';

type ServiceSectionProps = {
  onClickQuestion: (value: string, isOnboarding: boolean, step?: Steps) => void;
};

const ServicesSection = ({ onClickQuestion }: ServiceSectionProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isOnboardingMode = useAppSelector(selectIsOnboardingMode);
  const activeThread = useAppSelector(selectActiveThread);
  const localId = activeThread ? activeThread.id : null;
  const enableDemoFlow = useAppSelector(selectIsDemoFlowEnabled);
  const isDemoThread = useIsDemoThread(localId);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 47.5rem)' });

  const dates = getDatesToTranslations();

  const exemplaryQuestions = [
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_mantraining_one' }, dates),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_performservice_twenty_two' }, dates),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_performservice_twenty_three' }, dates),
  ];

  const onboardingQuestion = intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_onboarding_one' });

  const handleNext = () => {
    if (isDemoThread) {
      dispatch(resetStep());
    }
    onClickQuestion(onboardingQuestion, isOnboardingMode, 'step-0');
    if (!isSmallScreen) {
      dispatch(openSidebar());
    }
  };

  return (
    <Container>
      {isOnboardingMode && enableDemoFlow ? (
        <OnboardingServiceCard description={onboardingQuestion} onClickFunction={handleNext} />
      ) : null}
      {(isSmallScreen && isOnboardingMode && enableDemoFlow ? exemplaryQuestions.slice(0, 2) : exemplaryQuestions).map(
        (question, id) => (
          <ServiceCard
            description={question}
            onClickFunction={() => onClickQuestion(question, false)}
            key={`card-${id}`}
          />
        ),
      )}
    </Container>
  );
};

export default ServicesSection;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 0.5rem;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (max-width: 64rem) {
    flex-direction: column;
    align-items: start;
  }
`;
