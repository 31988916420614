import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { useState, useEffect } from 'react';
import { getUserProfile } from '@/configuration/login/loginSlice';
import { selectShowWelcomeAnimation, disableWelcomeAnimation } from '@/store/layout/layoutSlice';

const WelcomeSection = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(getUserProfile);
  const showWelcomeAnimation = useSelector(selectShowWelcomeAnimation);

  const [typedGreeting, setTypedGreeting] = useState('');
  const [typedName, setTypedName] = useState('');
  const [typedH4, setTypedH4] = useState('');
  const [showCursor, setShowCursor] = useState(true);

  const name = (user?.name || 'Guest').concat('!');
  const greetingText = intl.formatMessage({ id: 'chatPage.chatWindow.greeting' }, { name: '' }).trim();
  const h4Text = intl.formatMessage({ id: 'chatPage.chatWindow.offerOfAssistance.inquiry' });

  useEffect(() => {
    if (!showWelcomeAnimation) {
      setTypedGreeting(greetingText);
      setTypedName(name);
      setTypedH4(h4Text);
      setShowCursor(false);
      return;
    }

    const delay = 500;
    let typingTimeout: NodeJS.Timeout = setTimeout(() => {}, 0);

    typingTimeout = setTimeout(() => {
      let greetingIndex = 0;
      const greetingTypingInterval = setInterval(() => {
        if (greetingIndex <= greetingText.length) {
          setTypedGreeting(greetingText.slice(0, greetingIndex));
          greetingIndex++;
        } else {
          clearInterval(greetingTypingInterval);

          let nameIndex = 0;
          const nameTypingSpeed = 75;
          const nameTypingInterval = setInterval(() => {
            if (nameIndex <= name.length) {
              setTypedName(name.slice(0, nameIndex));
              nameIndex++;
            } else {
              clearInterval(nameTypingInterval);

              let h4Index = 0;
              const h4TypingSpeed = 90;
              const h4TypingInterval = setInterval(() => {
                if (h4Index <= h4Text.length) {
                  setTypedH4(h4Text.slice(0, h4Index));
                  h4Index++;
                } else {
                  clearInterval(h4TypingInterval);
                  setShowCursor(false);
                  dispatch(disableWelcomeAnimation());
                }
              }, h4TypingSpeed);

              return () => clearInterval(h4TypingInterval);
            }
          }, nameTypingSpeed);

          return () => clearInterval(nameTypingInterval);
        }
      }, 100);
    }, delay);

    return () => clearTimeout(typingTimeout);
  }, [showWelcomeAnimation, greetingText, name, h4Text, dispatch]);

  return (
    <Wrapper>
      <StyledHeading className="h3">
        <AnimatedText>
          {typedGreeting} <span className="text-color-primary">{typedName}</span>
          {showCursor && <Cursor>|</Cursor>}
        </AnimatedText>
      </StyledHeading>
      <StyledSubHeading className="h4">
        <AnimatedText>{typedH4}</AnimatedText>
      </StyledSubHeading>
    </Wrapper>
  );
};

export default WelcomeSection;

const StyledHeading = styled.h3`
  font-size: 3rem;
  @media (max-width: 48rem) {
    font-size: 1.6rem;
  }
`;

const StyledSubHeading = styled.h3`
  font-size: 2rem;
  @media (max-width: 48rem) {
    font-size: 1.2rem;
  }
`;

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  text-align: left;
  width: 100%;
  padding: 0 0.5rem;
  flex-shrink: 0;
  margin-bottom: 1rem;
`;

const AnimatedText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
`;

const Cursor = styled.span`
  display: inline-block;
  font-weight: bold;
  animation: ${blink} 0.7s step-end infinite;
`;
