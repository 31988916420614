import TreeSummary from '@rio-cloud/rio-uikit/components/assetTree/TreeSummary';
import TypeCounter from '@rio-cloud/rio-uikit/components/assetTree/TypeCounter';
import styled from 'styled-components';
import React from 'react';
import { FilterFAQsItem } from '@/store/faq/faqSlice';

type QuestionsAssetTreeSummaryProps = {
  onFilterSelect: (filter: string) => void;
  faqsList: FilterFAQsItem[];
  activeIcon: string | FilterFAQsItem['icon'];
};

const QuestionsAssetTreeSummary = ({ onFilterSelect, faqsList, activeIcon }: QuestionsAssetTreeSummaryProps) => {
  return (
    <StyledTreeSummary>
      <TreeSummary>
        {faqsList.map(({ icon, values }: FilterFAQsItem) => (
          <StyledTypeCounter key={`wrapper-counter-${icon}`}>
            <TypeCounter
              key={`counter-${icon}`}
              enableActivity
              icon={icon}
              type={icon}
              value={values.length}
              isActive={icon === activeIcon}
              onClick={(type: string) => onFilterSelect(type === activeIcon ? '' : type)}
            />
          </StyledTypeCounter>
        ))}
      </TreeSummary>
    </StyledTreeSummary>
  );
};

export default QuestionsAssetTreeSummary;

const StyledTreeSummary = styled.div`
  margin: 5%;
`;

const StyledTypeCounter = styled.div`
  cursor: pointer;
`;
