import { createSlice, createEntityAdapter, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { chatApi } from '../../services/chatApi/chatApi';
import { RootState } from '../store';
import { threadApi } from '../../services/threadApi/threadApi';
import { resetStep, Steps } from '../onboarding/onboardingSlice';
import { onboardingMessagesDE, onboardingMessagesEN } from '../../../mocks/mock.onboarding';
import { removeOnboardingThread, resetActiveThread } from '../thread/threadSlice';
import { setCurrentLayout } from '../layout/layoutSlice';

const roles = ['ai', 'human'] as const;

export type Role = (typeof roles)[number];

export type Message = {
  id: number;
  messageId?: string;
  role: Role;
  content: string;
  threadId: string | null;
  localThreadId: string | null;
  timestamp: string;
  isOnboardingQuestion?: boolean;
  userId?: string;
};

const messagesAdapter = createEntityAdapter<Message>({
  sortComparer: (a, b) => a.id - b.id,
});

type ChatState = {
  isFetchingHistory: boolean;
} & ReturnType<typeof messagesAdapter.getInitialState>;

const initialState: ChatState = {
  ...messagesAdapter.getInitialState(),
  isFetchingHistory: false,
};

const ONBOARDING_THREAD_ID = 'onboarding-thread';

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Omit<Message, 'id'>>) => {
      messagesAdapter.addOne(state, { ...action.payload, id: state.ids.length + 1 });
    },
    addManyMessages: (state, action: PayloadAction<Omit<Message, 'id'>[]>) => {
      const messagesWithIds = action.payload.map((message, index) => ({
        ...message,
        id: state.ids.length + 1 + index,
      }));
      messagesAdapter.addMany(state, messagesWithIds);
    },
    clearMessages: (state) => {
      messagesAdapter.removeAll(state);
    },
    addNextOnboardingQuestion: (state, action: PayloadAction<{ step: Steps; language: string }>) => {
      const { step, language } = action.payload;
      const onboardingMessages = language === 'de-DE' ? onboardingMessagesDE : onboardingMessagesEN;
      const nextQuestion = onboardingMessages[step]?.messages?.content.find((message) => message.role === 'ai');
      if (nextQuestion) {
        messagesAdapter.addOne(state, {
          ...nextQuestion,
          id: state.ids.length + 1,
          threadId: ONBOARDING_THREAD_ID,
          localThreadId: ONBOARDING_THREAD_ID,
          timestamp: new Date().toISOString(),
          isOnboardingQuestion: true,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeOnboardingThread, (state) => {
      const remainingMessages = state.ids.filter((id) => state.entities[id]?.threadId !== ONBOARDING_THREAD_ID);
      messagesAdapter.setAll(
        state,
        remainingMessages.map((id) => state.entities[id]!),
      );
    });
    builder.addCase(setCurrentLayout, (state, action) => {
      if (action.payload === 'onboardingMode') {
        const remainingMessages = state.ids.filter((id) => state.entities[id]?.threadId !== null);
        messagesAdapter.setAll(
          state,
          remainingMessages.map((id) => state.entities[id]!),
        );
      } else if (action.payload === 'faq') {
        const remainingMessages = state.ids.filter((id) => state.entities[id]?.threadId !== null);
        messagesAdapter.setAll(
          state,
          remainingMessages.map((id) => state.entities[id]!),
        );
      }
    });
    builder.addCase(resetStep, (state) => {
      const demoThreadId = state.ids.find((id) => {
        const message = state.entities[id];
        return message && message.localThreadId && message.localThreadId.startsWith('Demo');
      });

      if (demoThreadId) {
        const remainingMessages = state.ids.filter((id) => state.entities[id]?.threadId !== 'onboarding-thread');
        messagesAdapter.setAll(
          state,
          remainingMessages.map((id) => state.entities[id]!),
        );
      }
    });
    builder.addCase(resetActiveThread, (state) => {
      const remainingMessages = state.ids.filter((id) => state.entities[id]?.threadId !== null);

      messagesAdapter.setAll(
        state,
        remainingMessages.map((id) => state.entities[id]!),
      );
    });
    builder.addMatcher(chatApi.endpoints.sendMessage.matchFulfilled, (state, action) => {
      const { meta } = action;
      const localId = meta?.arg?.originalArgs?.meta?.localId;
      const threadId = action.payload.threadId;

      const messagesData: Message[] = action.payload.data;
      if (threadId && localId) {
        state.ids.forEach((id) => {
          const message = state.entities[id];
          if (message?.localThreadId === localId && message.threadId === null) {
            message.threadId = threadId;
            message.localThreadId = localId;
          }
        });
      }

      if (threadId) {
        const remainingMessages = state.ids.filter((id) => {
          const message = state.entities[id];
          return message?.threadId !== threadId && message?.threadId?.length !== 0;
        });
        messagesAdapter.setAll(
          state,
          remainingMessages.map((id) => state.entities[id]!),
        );
        messagesAdapter.addMany(state, messagesData);
      }
    });

    builder.addMatcher(
      threadApi.endpoints.fetchMessagesByThreadId.matchFulfilled,
      (state, action: PayloadAction<Message[]>) => {
        const fetchedThreadId = action.meta?.arg?.originalArgs?.threadId;

        if (action.payload) {
          const remainingMessages = state.ids.filter((id) => state.entities[id]?.threadId !== fetchedThreadId);
          messagesAdapter.setAll(
            state,
            remainingMessages.map((id) => state.entities[id]!),
          );
          messagesAdapter.upsertMany(state, action.payload);
        }
        state.isFetchingHistory = false;
      },
    );
    builder.addMatcher(threadApi.endpoints.fetchMessagesByThreadId.matchPending, (state, action) => {
      const threadId = action.meta?.arg?.originalArgs?.threadId;
      const hasMessages = state.ids.some((id) => state.entities[id]?.threadId === threadId);
      if (!hasMessages) {
        state.isFetchingHistory = true;
      }
    });
    builder.addMatcher(threadApi.endpoints.fetchMessagesByThreadId.matchRejected, (state) => {
      state.isFetchingHistory = false;
    });
  },
});

export const { addMessage, addManyMessages, clearMessages, addNextOnboardingQuestion } = chatSlice.actions;

export default chatSlice.reducer;

const { selectAll: getAllMessages, selectById: getMessageById } = messagesAdapter.getSelectors(
  (state: RootState) => state.chat,
);

export const selectMessageById = (messageId: number) =>
  createSelector(
    (state: RootState) => state,
    (state) => getMessageById(state, messageId),
  );

export const selectLastUserMessageBefore = (messageId: number) =>
  createSelector(
    (state: RootState) => getAllMessages(state),
    (messages) => {
      const currentIndex = messages.findIndex((message) => message.id === messageId);
      if (currentIndex === -1) {
        return null;
      }

      for (let i = currentIndex - 1; i >= 0; i--) {
        if (messages[i].role === 'human') {
          return messages[i];
        }
      }
      return null;
    },
  );

export const selectMessagesByThreadId = (threadId: string | null) =>
  createSelector(
    (state: RootState) => getAllMessages(state),
    (messages) =>
      messages
        .filter((message) => message.threadId === threadId)
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()),
  );

export const selectIsFetchingHistory = (state: RootState) => state.chat.isFetchingHistory;
