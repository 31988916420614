import { useIntl } from 'react-intl';

const NoMessageAlert = () => {
  const intl = useIntl();

  return (
    <div className="alert alert-dismissible alert-primary width-50pct">
      <div className="display-flex gap-10">
        <span className="text-color-primary text-size-h4 rioglyph rioglyph-info-sign" />
        <div>
          <strong className="text-size-16">
            {intl.formatMessage({ id: 'chatPage.chatThreadList.error.noMessage.title' })}
          </strong>
          <div className="margin-y-5">{intl.formatMessage({ id: 'chatPage.chatThreadList.error.noMessage' })}</div>
        </div>
      </div>
    </div>
  );
};

export default NoMessageAlert;
