import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import Button from '@rio-cloud/rio-uikit/Button';
import { useMediaQuery } from 'react-responsive';
import ChatThreadCategory from '@/components/molecule/ChatThreadCategory/ChatThreadCategory';
import { getUserProfile } from '@/configuration/login/loginSlice';
import { useFetchThreadsQuery } from '@/services/threadApi/threadApi';
import { getUserId } from '@/utils/getUserId';
import { removeOnboardingThread, resetActiveThread, selectFavoriteThreads } from '@/store/thread/threadSlice';
import SearchField from '@/components/atom/SearchField/SearchField';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import { disableOnboardingMode } from '@/store/onboarding/onboardingSlice';
import { closeSidebar } from '@/store/sidebar/sidebarSlice';

type FavoritesThreadsListProps = {
  isLoading: boolean;
};

const FavoritesThreadsList = ({ isLoading }: FavoritesThreadsListProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserProfile);
  const { data } = useFetchThreadsQuery(
    { userId: getUserId(user?.sub ?? '') },
    { refetchOnMountOrArgChange: true, skip: false },
  );
  const favoriteThreads = useAppSelector(selectFavoriteThreads);
  const [threadsList, setThreadsList] = useState(favoriteThreads);
  const [searchValue, setSearchValue] = useState('');
  const isSmallScreen = useMediaQuery({ query: '(max-width: 47.5rem)' });

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filteredLists = favoriteThreads.filter((item) =>
      (item.title as string).toLowerCase().includes(value.toLowerCase()),
    );
    setThreadsList(filteredLists);
  };

  const handleOnClick = () => {
    dispatch(resetActiveThread());
    dispatch(disableOnboardingMode());
    dispatch(removeOnboardingThread());
    if (isSmallScreen) {
      dispatch(closeSidebar());
    }
  };

  useEffect(() => {
    setThreadsList(favoriteThreads);
    handleSearch(searchValue);
  }, [favoriteThreads]);

  return (
    <div className="padding-y-20">
      <div className="display-flex justify-content-start margin-bottom-20 padding-x-20 width-100pct">
        <Button
          className="width-100pct"
          bsStyle="primary"
          iconName="rioglyph rioglyph-plus-light"
          disabled={isLoading}
          onClick={handleOnClick}
        >
          <FormattedMessage id={'chatPage.chatThreadList.threadDeletion.addNewChat.label'} />
        </Button>
      </div>
      <div className="margin-x-20 text-medium text-size-14 margin-bottom-20">
        {intl.formatMessage({ id: 'chatPage.chatThreadList.favorite.title' })}
      </div>
      {favoriteThreads && favoriteThreads.length > 0 ? (
        <div>
          <SearchField onSearch={handleSearch} />
          <ChatThreadCategory threads={threadsList} searchWord={searchValue} />
        </div>
      ) : (
        <p className="padding-20">
          {intl.formatMessage({ id: 'chatPage.chatThreadList.threadAddToFavorites.noFavorites.label' })}
        </p>
      )}
    </div>
  );
};

export default FavoritesThreadsList;
