import { KeyboardEventHandler, MouseEvent, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { useIntl } from 'react-intl';
import useHover from '@rio-cloud/rio-uikit/hooks/useHover';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import Highlighter from 'react-highlight-words';
import { useMediaQuery } from 'react-responsive';
import {
  resetActiveThread,
  selectActiveThread,
  selectThreadById,
  setActiveThread,
  toggleFavorite,
  updateThreadTitle,
} from '@/store/thread/threadSlice';
import { getUserProfile } from '@/configuration/login/loginSlice';
import { threadApi, useDeleteThreadMutation, useUpdateThreadMutation } from '../../../services/threadApi/threadApi';
import { getUserId } from '@/utils/getUserId';
import ActionIcon from '@/components/atom/ActionIcon/ActionIcon';
import { RootState } from '@/store/store';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import { closeSidebar } from '@/store/sidebar/sidebarSlice';
import { EllipsisText } from '@/utils/EllipsisText';
import { disableOnboardingMode } from '@/store/onboarding/onboardingSlice';

const texts = {
  removeFromFavorites: 'chatPage.chatThreadList.threadRemoveFromFavorites.label',
  addToFavorites: 'chatPage.chatThreadList.threadAddToFavorites.label',
  editThreadTitle: 'chatPage.chatThreadList.editThreadTitle.label',
  threadDeletion: 'chatPage.chatThreadList.threadDeletion.label',
  threadDeletionSuccess: 'chatPage.chatThreadList.threadDeletion.success.notification',
  removeFromFavoritesSuccess: 'chatPage.chatThreadList.threadRemoveFromFavorites.success.notification',
  threadDeletionError: 'chatPage.chatThreadList.threadDeletion.error',
  addToFavoritesSuccess: 'chatPage.chatThreadList.threadAddToFavorites.success.notification',
  removingFavoritesError: 'chatPage.chatThreadList.threadToggleFavorites.errorRemoving.notification',
  addingFavoritesError: 'chatPage.chatThreadList.threadToggleFavorites.errorAdding.notification',
  confirmDeletionTitle: 'chatPage.chatThreadList.confirmDeletion.title',
  confirmDeletionContent: 'chatPage.chatThreadList.confirmDeletion.content',
  cancel: 'common.cancel',
  confirm: 'common.confirm',
};

const classes = {
  editIcon: 'rioglyph rioglyph-pencil text-color-light text-size-18 hover-text-color-primary',
  favoriteIcon: 'rioglyph rioglyph-star margin-left-10 text-color-primary text-size-18',
  unfavoriteIcon: 'rioglyph rioglyph-star  margin-left-10 text-color-light text-size-18 hover-text-color-primary',
  deleteIcon: 'rioglyph rioglyph-trash text-color-light text-size-18 hover-text-color-primary',
  clearIcon: 'rioglyph rioglyph-remove-sign margin-left-5 text-color-primary text-size-18',
  inputTitle: 'width-100pct form-control bg-none text-size-14',
};

type ThreadListItemProps = {
  index: number;
  localId: string;
  threadId: string | null;
  firstMessage: string;
  isFavorite: boolean;
  searchWord?: string;
  testId: number;
};

const ThreadListItem = ({
  index,
  localId,
  threadId,
  testId,
  firstMessage,
  isFavorite,
  searchWord,
}: ThreadListItemProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const activeThreadId = useAppSelector(selectActiveThread)?.id;
  const thread = useAppSelector((state: RootState) => selectThreadById(state, localId));
  const threadTitle = thread ? thread.title : '';
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [title, setTitle] = useState(threadTitle ?? firstMessage);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const listItemRef = useRef<HTMLLIElement>(null);
  const isHovered = useHover(listItemRef);
  const storedActiveThreadId = sessionStorage.getItem('activeThreadId');
  const isActive = activeThreadId === localId || storedActiveThreadId === localId;
  const isSmallScreen = useMediaQuery({ query: '(max-width: 47.5rem)' });
  const isMobileOrTablet = useMediaQuery({ maxWidth: '64rem' });

  const [deleteThread] = useDeleteThreadMutation();
  const [updateThreadTitleMutation] = useUpdateThreadMutation();
  const [toggleFavoriteMutation, { isLoading: isLoadingFavorite }] = useUpdateThreadMutation();

  const [fetchMessagesByThreadId] = threadApi.endpoints.fetchMessagesByThreadId.useLazyQuery();

  const handleConfirmDelete = async () => {
    try {
      if (threadId) {
        const response = await deleteThread({ userId, threadId }).unwrap();
        if (response.status === 'success') {
          Notification.success(intl.formatMessage({ id: texts.threadDeletionSuccess }));
          dispatch(resetActiveThread());
        } else {
          Notification.error(intl.formatMessage({ id: texts.threadDeletionError }));
        }
      }
    } catch (error) {
      Notification.error(intl.formatMessage({ id: texts.threadDeletionError }));
      console.log(error);
    } finally {
      setShowDeleteDialog(false);
    }
  };

  const handleToggleFavorite = async () => {
    try {
      if (threadId) {
        const response = await toggleFavoriteMutation({
          userId,
          threadId,
          updateData: { favorite: !isFavorite },
        }).unwrap();
        dispatch(toggleFavorite(localId));

        Notification.success(
          intl.formatMessage({
            id: !isFavorite ? texts.addToFavoritesSuccess : texts.removeFromFavoritesSuccess,
          }),
        );
      }
    } catch (error) {
      Notification.error(
        intl.formatMessage({ id: isFavorite ? texts.removingFavoritesError : texts.addingFavoritesError }),
      );
      console.log(error);
    }
  };

  const handleEditThreadTitle = () => setIsEditing(true);

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter' && isEditing) {
      handleUpdateThreadTitle();
    }
  };

  const handleUpdateThreadTitle = async () => {
    setIsEditing(false);
    if (firstMessage !== title && threadId) {
      try {
        await updateThreadTitleMutation({ userId, threadId, updateData: { title } }).unwrap();
        dispatch(updateThreadTitle({ title }));
      } catch (error) {
        Notification.error(intl.formatMessage({ id: texts.threadDeletionError }));
      }
    }
  };

  const handleClearTitle = () => {
    setTitle('');
    inputRef.current?.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        setTitle(firstMessage);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [firstMessage]);

  const handleIconClick = (e: MouseEvent<HTMLSpanElement>, handler: () => void) => {
    e.stopPropagation();
    handler();
  };

  const iconProps = [
    {
      onClick: (e: MouseEvent<HTMLSpanElement>) => handleIconClick(e, handleEditThreadTitle),
      iconClass: classes.editIcon,
      tooltipText: texts.editThreadTitle,
    },
    {
      onClick: (e: MouseEvent<HTMLSpanElement>) => handleIconClick(e, () => setShowDeleteDialog(true)),
      iconClass: classes.deleteIcon,
      tooltipText: texts.threadDeletion,
    },
  ];

  useEffect(() => {
    if (threadId && activeThreadId === localId && threadId !== 'onboarding-thread') {
      fetchMessagesByThreadId({ userId, threadId });
    }
  }, [threadId, activeThreadId, localId]);

  useEffect(() => {
    if ((threadId && storedActiveThreadId === threadId) || storedActiveThreadId === localId) {
      if (threadId && threadId !== 'onboarding-thread') {
        fetchMessagesByThreadId({ userId, threadId });
        dispatch(setActiveThread(localId));
      }
    }
  }, [storedActiveThreadId]);

  const handleOnClick = async () => {
    if (threadId === 'onboarding-thread') {
      dispatch(setActiveThread(localId));
    }
    if (activeThreadId !== localId && localId !== 'onboarding-thread') {
      dispatch(resetActiveThread());
      dispatch(disableOnboardingMode());
      dispatch(setActiveThread(localId));
    }
    if (threadId && localId !== 'onboarding-thread') {
      await fetchMessagesByThreadId({ userId, threadId }).unwrap();
    }
    if (isSmallScreen) {
      dispatch(closeSidebar());
    }
  };

  return (
    <StyledThreadListItemManager key={`list-item-wrapper-${index}`}>
      {!isEditing ? (
        <StyledThreadListItem
          ref={listItemRef}
          onClick={handleOnClick}
          $isactive={isActive}
          $search={searchWord}
          data-testid={`chat-thread-${testId}`}
        >
          {!searchWord || searchWord.length === 0 ? (
            <EllipsisText>{title}</EllipsisText>
          ) : (
            <Highlighter
              highlightStyle={highlightStyle}
              searchWords={[searchWord]}
              autoEscape={false}
              textToHighlight={title}
            />
          )}
          <StyledItemAction $search={searchWord}>
            {(isHovered || searchWord || searchWord?.length !== 0 || isMobileOrTablet) && (
              <IconContainer>
                {iconProps.map(({ onClick, iconClass, tooltipText }) => (
                  <ActionIcon key={tooltipText} onClick={onClick} iconClass={iconClass} tooltipText={tooltipText} />
                ))}
              </IconContainer>
            )}
            {isLoadingFavorite ? (
              <div className="margin-left-10">
                <Spinner />
              </div>
            ) : (
              <FavoriteIcon
                onClick={(e) => handleIconClick(e, handleToggleFavorite)}
                iconClass={isFavorite ? classes.favoriteIcon : classes.unfavoriteIcon}
                tooltipText={isFavorite ? texts.removeFromFavorites : texts.addToFavorites}
                isFavorite={isFavorite}
              />
            )}
          </StyledItemAction>
          <ConfirmationDialog
            show={showDeleteDialog}
            title={intl.formatMessage({ id: texts.confirmDeletionTitle })}
            content={intl.formatMessage({ id: texts.confirmDeletionContent })}
            onClickConfirm={handleConfirmDelete}
            onClickCancel={() => setShowDeleteDialog(false)}
            cancelButtonText={intl.formatMessage({ id: texts.cancel })}
            confirmButtonText={
              <>
                <span className="rioglyph rioglyph-ok margin-right-5" />
                <span>{intl.formatMessage({ id: texts.confirm })}</span>
              </>
            }
          />
        </StyledThreadListItem>
      ) : (
        <InputContainer>
          <StyledInput
            ref={inputRef}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={handleInputKeyDown}
            autoFocus
            className={classes.inputTitle}
          />
          {title && <ActionIcon onClick={handleClearTitle} iconClass={classes.clearIcon} tooltipText={texts.cancel} />}
        </InputContainer>
      )}
    </StyledThreadListItemManager>
  );
};

export default ThreadListItem;

const StyledThreadListItem = styled.li<{ $isactive: boolean; $search?: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  padding: 1.4rem 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: visible;

  &:hover {
    background-color: var(--gray-lightest);
  }

  ${({ $isactive }) =>
    $isactive &&
    css`
      background-color: var(--color-highlight-decent);

      & > p {
        margin-left: 2rem;
      }
    `}

  ${({ $isactive, $search }) =>
    $isactive &&
    !$search &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 2rem;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background-color: var(--brand-primary);
        border-radius: 50%;
        box-shadow: 0 0 5px 3px var(--color-highlight-lighter);
      }
    `}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledThreadListItemManager = styled.div`
  border-radius: 4px;
  padding: 0;
  transition: background-color 0.3s ease;
`;

const InputContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  margin: 1rem;
`;

type FavoriteIconProps = {
  isFavorite: boolean;
};

const FavoriteIcon = styled(ActionIcon)<FavoriteIconProps>`
  display: inline-flex;
  color: ${({ isFavorite }) => (isFavorite ? 'var(--color-favorite)' : 'inherit')};
`;

const highlightStyle = {
  backgroundColor: 'var(--color-highlight-lighter)',
  padding: '0px',
};

const StyledItemAction = styled.div<{ $search: string | undefined }>`
  display: flex;
  justify-content: flex-end;
  ${({ $search }) =>
    $search &&
    $search.length > 0 &&
    css`
      width: 100%;
      padding: 0.5rem;
    `}
`;
