import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';

export type RobotMessage = {
  title?: string;
  text: string;
  buttons?: {
    text: string;
    onClick: () => void;
  }[];
};

type AnimatedRobotSpeechBubbleRobotProps = {
  messages: RobotMessage[];
};

const AnimatedRobotSpeechBubbleRobot = ({ messages }: AnimatedRobotSpeechBubbleRobotProps) => {
  const intl = useIntl();
  return (
    <ChatContainer>
      {messages.map((msg, index) => (
        <SpeechBubbleRobot key={index} $delay={index * 0.5}>
          {msg.title ? <Title>{intl.formatMessage({ id: msg.title })}</Title> : null}
          <Text>{intl.formatMessage({ id: msg.text })}</Text>
          {msg.buttons ? (
            <ButtonContainer>
              {msg.buttons.map((button, i) => (
                <button key={i} type="button" className="btn btn-outline btn-secondary btn-sm" onClick={button.onClick}>
                  {intl.formatMessage({ id: button.text })}
                </button>
              ))}
            </ButtonContainer>
          ) : null}
        </SpeechBubbleRobot>
      ))}
    </ChatContainer>
  );
};

export default AnimatedRobotSpeechBubbleRobot;

const flyInAnimation = keyframes`
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  top: 20px;
  margin-left: 4rem;
  margin-right: 1rem;
  margin-bottom: 6rem;
  height: 100%;
`;

const SpeechBubbleRobot = styled.div<{ $delay: number }>`
  background-color: var(--color-highlight-lightest);
  border-radius: 10px;
  padding: 1.5rem;
  position: relative;
  max-width: 100%;
  opacity: 0;
  transform: translateY(30px);
  animation: ${flyInAnimation} 0.8s ease-out forwards;
  animation-delay: ${(props) => props.$delay}s;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
`;

const Text = styled.div`
  margin-top: 0.5rem;
  font-size: 1.1rem;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 18.75rem;
  & > button {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: 100%;
  }
`;
