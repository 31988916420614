import styled from 'styled-components';
import { useState } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/components/overlay/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/components/tooltip/Tooltip';
import { MessageActionBar } from '../MessageActionBar/MessageActionBar';
import { Role, selectMessageById } from '@/store/chat/chat.slice';
import { useAppSelector } from '@/configuration/setup/hooks';
import { useMediaQuery } from 'react-responsive';
import BubbleContent from '../../molecule/BubbleContent/BubbleContent';
import { Steps } from '@/store/onboarding/onboardingSlice';
import { useIntl } from 'react-intl';

export type SpeechBubbleProps = {
  messageId: number;
  isLoading?: boolean;
  localThreadId: string | null;
  handleSend: (content: string, isOnboarding: boolean, nextStepIndex?: Steps) => void;
};

const SpeechBubble = ({ messageId, localThreadId, isLoading, handleSend }: SpeechBubbleProps) => {
  const intl = useIntl();
  const { role, isOnboardingQuestion } = useAppSelector(selectMessageById(messageId));
  const isMobileOrTablet = useMediaQuery({ maxWidth: '64rem' });
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => setIsEditing(true);

  return (
    <BubbleWrapper role={role}>
      {role === 'ai' ? (
        <div className="text-color-darkest text-size-14 text-medium margin-bottom-5 gap-5 align-items-center">
          <span className="rioglyph rioglyph-robot text-size-16 margin-right-5" aria-hidden="true" />
          <span>FleetAssistant</span>
        </div>
      ) : null}
      <HoverArea $ismobileortablet={isMobileOrTablet} role={role}>
        {role === 'human' && !isEditing ? (
          <OverlayTrigger
            key={'edit-tooltip'}
            placement={'auto-start'}
            overlay={
              <Tooltip id="tooltip-edit" allowOnTouch>
                <span>{intl.formatMessage({ id: 'chatPage.SpeechBubble.edit.tooltip' })}</span>
              </Tooltip>
            }
          >
            <EditIcon onClick={handleEditClick} data-testid="icon-edit" className="rioglyph rioglyph-pencil" />
          </OverlayTrigger>
        ) : null}
        <Bubble role={role} $isediting={isEditing} data-testid={`speech_bubble-${role}`}>
          {messageId && isLoading !== undefined ? (
            <BubbleContent
              messageId={messageId}
              isLoading={isLoading}
              localThreadId={localThreadId}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              handleSend={handleSend}
            />
          ) : null}
          {role === 'ai' && !isOnboardingQuestion ? (
            <MessageActionBar messageId={messageId} handleSend={handleSend} />
          ) : null}
        </Bubble>
      </HoverArea>
    </BubbleWrapper>
  );
};

export default SpeechBubble;

const EditIcon = styled.span`
  border-radius: 50%;
  padding: 0.7rem;
  background: var(--gray-lightest);
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  top: 15px;
  left: -40px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;

  &:hover {
    opacity: 1;
    visibility: visible;
  }
`;

const HoverArea = styled.div<{ $ismobileortablet: boolean; role: Role }>`
  position: relative;
  display: flex;
  justify-content: ${({ role }) => (role === 'human' ? 'flex-end' : 'flex-start')};

  ${EditIcon} {
    opacity: ${({ $ismobileortablet }) => ($ismobileortablet ? 1 : 0)};
    visibility: ${({ $ismobileortablet }) => ($ismobileortablet ? 'visible' : 'hidden')};
  }

  &:hover ${EditIcon} {
    opacity: 1;
    visibility: visible;
  }
`;

const BubbleWrapper = styled.div<{ role: Role }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ role }) => (role === 'human' ? 'flex-end' : 'flex-start')} !important;
  align-items: ${({ role }) => (role === 'human' ? 'end' : 'start')} !important;
  margin: 0.8rem 0;
  padding-left: ${({ role }) => (role === 'human' ? '4rem' : 0)} !important;
  width: 100%;
  position: relative;
`;

const Bubble = styled.div<{ role: Role; $isediting: boolean }>`
  background: ${({ role, $isediting }) =>
    $isediting ? 'transparent' : role === 'human' ? 'var(--gray-lightest)' : 'var(--color-highlight-lightest)'};
  color: var(--gray-darkest);
  padding: 1rem 2rem;
  margin-top: ${({ role }) => (role === 'human' ? '1rem' : 0)};
  word-wrap: break-word;
  border-radius: 6px;
  position: relative;
  min-width: min(25rem, 5%);

  @media (max-width: 64rem) {
    padding: 0.5rem 1.4rem;
  }
`;
