import TableActionBar from '@/components/organism/TableActionBar/TableActionBar';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';

type TableProps = {
  data: any;
  message: string;
  showActions: boolean;
};

const Table = ({ data, message, showActions }: TableProps) => {
  if (!data || (Array.isArray(data) && data.length === 0)) {
    return null;
  }

  const isArray = Array.isArray(data);
  const columns = isArray ? Object.keys(data[0]) : Object.keys(data);

  return (
    <Fragment>
      <StyledTable className="table table-head-filled width-100pct">
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isArray ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col) => (
                  <td key={col}>{row[col]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              {columns.map((col) => (
                <td key={col}>{data[col]}</td>
              ))}
            </tr>
          )}
        </tbody>
      </StyledTable>
      {showActions ? <TableActionBar message={message} /> : null}
    </Fragment>
  );
};

export default Table;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  word-break: break-word;
  white-space: nowrap;
`;
