import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import AnimatedRobotSpeechBubble from '@/components/molecule/AnimatedRobotSpeechBubble/AnimatedRobotSpeechBubble';
import AnimatedRobot from '@/components/atom/AnimatedRobot/AnimatedRobot';
import { useAppDispatch } from '@/configuration/setup/hooks';
import { toggleSidebar } from '@/store/sidebar/sidebarSlice';

const FeedbackInfoSidebar = () => {
  const dispatch = useAppDispatch();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 47.5rem)' });

  const texts = {
    titlePartOne: 'feedbackPage.sidebar.title.partOne',
    descriptionPartOne: 'feedbackPage.sidebar.description.partOne',
    descriptionPartTwo: 'feedbackPage.sidebar.description.partTwo',
    button: 'feedbackPage.sidebar.description.partTwo.button',
  } as const;

  const handleCloseSidebar = () => dispatch(toggleSidebar());

  const messages = [
    {
      title: texts.titlePartOne,
      text: texts.descriptionPartOne,
    },
    {
      text: texts.descriptionPartTwo,
      ...(isSmallScreen
        ? {
            buttons: [
              {
                text: texts.button,
                onClick: handleCloseSidebar,
              },
            ],
          }
        : {}),
    },
  ];

  return (
    <Container>
      <div>
        <AnimatedRobotSpeechBubble messages={messages} />
        <AnimatedRobot isVisible />
      </div>
    </Container>
  );
};

export default FeedbackInfoSidebar;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 12.5rem;
  height: 100%;
  width: fit-content;
  justify-content: flex-end;
  padding: 2rem;
`;
