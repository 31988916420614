import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectCurrentPage, selectCurrentPageProps } from '@/store/about/aboutSlice';
import { ComponentToElement } from '@/store/about/pageToContent';
import HowAssistantWorks from '@/components/organism/HowAssistantWorks/HowAssistantWorks';
import WhatIsFleetAssistant from '@/components/organism/WhatIsFleetAssistant/WhatIsFleetAssistant';
import FrequentlyAskedQuestions from '@/components/organism/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import Welcome from '@/components/organism/Welcome/Welcome';

export const aboutMap: ComponentToElement = {
  welcomePage: <Welcome />,
  fleetAssistantOverview: <WhatIsFleetAssistant />,
  assistantFunctionality: <HowAssistantWorks />,
  frequentlyAskedQuestions: <FrequentlyAskedQuestions />,
};

const GetStartedPage = () => {
  const activePage = useSelector(selectCurrentPage);
  const activePageProps = useSelector(selectCurrentPageProps);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePage]);

  return (
    <Container>
      <Article>
        {activePage !== 'page1' ? (
          <StyledHeading className="h2 margin-bottom-15 margin-top-0">
            <FormattedMessage id={activePageProps.translation} />
          </StyledHeading>
        ) : null}
        {aboutMap[activePageProps.componentId]}
      </Article>
    </Container>
  );
};

export default GetStartedPage;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: start;
`;

const Article = styled.div`
  max-width: 110rem;
  width: 100%;
`;

const StyledHeading = styled.h2`
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
`;
