import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { selectMessagesByThreadId, selectIsFetchingHistory } from '@/store/chat/chat.slice';
import { selectIsOnboardingQuestionLoading, Steps } from '@/store/onboarding/onboardingSlice';
import { selectActiveThread, selectIsThreadLoading } from '@/store/thread/threadSlice';
import ChatInput from '@/components/molecule/ChatInput/ChatInput';
import SafetyNotice from '@/components/organism/SafetyNotice/SafetyNotice';
import ServicesSection from '@/components/organism/ServicesSection/ServicesSection';
import SpeechBubble from '@/components/organism/SpeechBubble/SpeechBubble';
import WelcomeSection from '@/components/molecule/WelcomeSection/WelcomeSection';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import ProcessingStatuses from '@/components/molecule/ProcessingStatuses/ProcessingStatuses';
import { addStatus, clearStatuses, selectStatuses } from '@/store/processingStatus/processingStatusSlice';
import NoMessageAlert from '@/components/atom/NoMessageAlert/NoMessageAlert';

const SAFETY_NOTICE_KEY = 'termsAcceptedAt';
const DAYS_90 = 90 * 24 * 60 * 60 * 1000;

const knowledgeBaseSteps = {
    1: {
        id: 'onb-step-1-knowledge-base',
        message: 'chatRouting.processing.determiningService',
        completed: false,
        visible: true,
        threadId: 'onboarding-thread',
        localThreadId: 'onboarding-thread',
    },
    2: {
        id: 'onb-step-2-knowledge-base',
        message: 'knowledgeBase.processing.searchingDocuments',
        completed: false,
        visible: true,
        threadId: 'onboarding-thread',
        localThreadId: 'onboarding-thread',
    },
    3: {
        id: 'onb-step-3-knowledge-base',
        message: 'knowledgeBase.processing.documents',
        completed: false,
        visible: true,
        threadId: 'onboarding-thread',
        localThreadId: 'onboarding-thread',
    },
    4: {
        id: 'onb-step-4-knowledge-base',
        message: 'knowledgeBase.processing.finalAnswer',
        completed: false,
        visible: true,
        threadId: 'onboarding-thread',
        localThreadId: 'onboarding-thread',
    },
};


const performSteps = {
    1: {
        id: 'onb-step-1-perform',
        message: 'chatRouting.processing.determiningService',
        completed: false,
        visible: true,
        threadId: 'onboarding-thread',
        localThreadId: 'onboarding-thread',
    },
    2: {
        id: 'onb-step-2-perform',
        message: 'performService.processing.performData',
        completed: false,
        visible: true,
        threadId: 'onboarding-thread',
        localThreadId: 'onboarding-thread',
    },
    3: {
        id: 'onb-step-3-perform',
        message: 'performService.processing.finalAnswer',
        completed: false,
        visible: true,
        threadId: 'onboarding-thread',
        localThreadId: 'onboarding-thread',
    },
};

type ChatWindowProps = {
  handleSend: (content: string, isOnboarding?: boolean, nextStepIndex?: Steps) => Promise<void>;
  isLoading?: boolean;
  isError?: boolean;
};

const ChatWindow = ({ handleSend, isError, isLoading }: ChatWindowProps) => {
  const dispatch = useAppDispatch();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const onboardingTimeouts = useRef<NodeJS.Timeout[]>([]);
  const activeThread = useAppSelector(selectActiveThread);
  const localId = activeThread ? activeThread.id : null;
  const processingStatusesSSE = useAppSelector(selectStatuses);
  const isOnboardingQuestionLoading = useAppSelector(selectIsOnboardingQuestionLoading);
  const messages = useAppSelector((state) =>
    activeThread ? selectMessagesByThreadId(activeThread?.threadId)(state) : undefined,
  );
  const isThreadsLoading = useAppSelector(selectIsThreadLoading(localId));
  const isHistoryFetching = useAppSelector(selectIsFetchingHistory);

  const [showSafetyNotice, setShowSafetyNotice] = useState(false);
  const [isSafetyNoticeChecked, setIsSafetyNoticeChecked] = useState(localStorage.getItem(SAFETY_NOTICE_KEY));

  const startDate = parseInt(localStorage.getItem(SAFETY_NOTICE_KEY) ?? '0', 10);
  const hiddenUntil = isNaN(startDate) ? 0 : new Date(startDate).getTime();
  const now = Date.now();

  const handleSendFromInput = async (message: string) => {
    await handleSend(message);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSetShow = (value: boolean) => setShowSafetyNotice(value);

  useEffect(() => {
    if (now - hiddenUntil > DAYS_90) {
      setShowSafetyNotice(true);
    }
  }, []);

  const handleCheckSafetyNoticeDate = () => setIsSafetyNoticeChecked((prev) => (prev === 'true' ? 'false' : 'true'));

  useEffect(() => {
    if (isSafetyNoticeChecked) {
      localStorage.setItem(SAFETY_NOTICE_KEY, Date.now().toString());
    }
  }, [isSafetyNoticeChecked]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoading]);

  const handleOnboardingSteps = (step: Steps) => {
    const steps = step === 'step-4' ? knowledgeBaseSteps : performSteps;
    const stepKeys = Object.keys(steps);
  
    stepKeys.forEach((key, index) => {
      const timeout = setTimeout(() => {
        dispatch(addStatus(steps[key]));
      }, index * 6000);
      onboardingTimeouts.current.push(timeout);
      dispatch(clearStatuses());
    });
  };
  
  useEffect(() => {
    if (isOnboardingQuestionLoading) {
      onboardingTimeouts.current.forEach(clearTimeout);
      onboardingTimeouts.current = [];
      handleOnboardingSteps('step-4');
    }
  }, [isOnboardingQuestionLoading]);

  return (
    <ChatWindowWrapper>
      {showSafetyNotice && !isSafetyNoticeChecked ? (
        <SafetyNotice
          show={showSafetyNotice}
          handleOnChange={handleCheckSafetyNoticeDate}
          checked={isSafetyNoticeChecked === 'true' ? true : false}
          setShow={handleSetShow}
          onClose={() => setShowSafetyNotice(false)}
        />
      ) : null}
      <ContentWrapper>
        {Array.isArray(messages) && messages.length === 0 && messages.length === 0 && isHistoryFetching ? (
          <Spinner />
        ) : null}
        {Array.isArray(messages) &&
        messages.length === 0 &&
        !isThreadsLoading &&
        !isHistoryFetching &&
        activeThread?.threadId !== null ? (
          <NoMessageAlert />
        ) : null}
        {(!Array.isArray(messages) || activeThread?.threadId === null) &&
        !isThreadsLoading &&
        !isHistoryFetching &&
        !isError ? (
          <Fragment>
            <WelcomeSection />
            <ServicesSection onClickQuestion={(message, isOnboarding) => handleSend(message, isOnboarding, 'step-1')} />
          </Fragment>
        ) : null}
        <MessagesSection>
          {Array.isArray(messages) &&
            messages.map((msg, index) => (
              <SpeechBubble
                key={`speechBubble-${index}`}
                messageId={msg.id}
                handleSend={handleSend}
                isLoading={isLoading}
                localThreadId={msg.localThreadId}
              />
            ))}
          {(isThreadsLoading && isLoading) || isOnboardingQuestionLoading ? (
            <ProcessingStatuses steps={processingStatusesSSE} />
          ) : null}
          <div ref={messagesEndRef} style={{ marginBottom: '-0.5rem' }} />
        </MessagesSection>
      </ContentWrapper>
      <ChatInput isLoading={isThreadsLoading} onSendMessage={(message) => handleSendFromInput(message)} />
    </ChatWindowWrapper>
  );
};

export default ChatWindow;

const ChatWindowWrapper = styled.div`
  padding: 2rem;
  background: var(--color-white);
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% + 1rem);
  padding-right: 2rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: visible;
  height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--color-black), 0.4);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(var(--color-black), 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  scrollbar-width: thin;
  scrollbar-color: rgba(var(--color-black), 0.4) rgba(var(--color-black), 0.1);
`;

const MessagesSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
