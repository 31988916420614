import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import NewFeaturesSection from '../NewFeaturesSection/NewFeaturesSection';
import OverviewCards from '../OverviewCards/OverviewCards';
import PageNavigation from '../PageNavigation/PageNavigation';
import { setCurrentPage } from '@/store/about/aboutSlice';

const texts = {
  introduction: 'getStartedPage.whatIsFleetAssistant.introduction',
  expanderTitle: 'getStartedPage.expanderPanelWarning.title',
  previousLabel: 'getStartedPage.welcome.label',
  nextLabel: 'getStartedPage.howVirtualAssistantWorks.label',
};

const classes = {
  container: 'margin-top-25',
  sectionTitle: 'text-size-h3 text-bold padding-x-20',
};

const WhatIsFleetAssistant = () => {
  const dispatch = useDispatch();
  const isDarkMode = useDarkMode();

  const pageTopRef = useRef<HTMLDivElement>(null);

  const handlePreviousSection = () => dispatch(setCurrentPage('page1'));
  const handleNextSection = () => dispatch(setCurrentPage('page3'));

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={classes.container}>
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <IntroSection>
        <TextContainer>
          <p>
            <FormattedMessage id={texts.introduction} />
          </p>
        </TextContainer>
        <ImageContainer>
          <img src="assets/images/hero-image.svg" alt="" />
        </ImageContainer>
      </IntroSection>
      <OverviewCards />
      <NewFeaturesSection />
      <Container>
        <PageNavigation
          onNext={handleNextSection}
          onPrevious={handlePreviousSection}
          previousLabel={texts.previousLabel}
          nextLabel={texts.nextLabel}
        />
      </Container>
    </div>
  );
};

export default WhatIsFleetAssistant;

const IntroSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem;
  background: linear-gradient(135deg, var(--brand-secondary-decent), var(--gray-lightest));
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(var(--color-black), 0.1);
  margin-bottom: 2.5rem;

  @media (max-width: 48rem) {
    flex-direction: column;
    text-align: left;
    background: none;
    padding: 0.5rem;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-right: 2rem;

  p {
    line-height: 1.7;
    color: var(--gray-darkest);
  }

  @media (max-width: 48rem) {
    padding-right: 0;
    margin-bottom: 1.5rem;
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 11.25rem;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 48rem) {
    flex: 0 0 auto;
    img {
      width: 100%;
    }
  }
`;

const Container = styled.div`
  padding: 1.5rem 1rem;
  background: var(--color-white);
`;
