import Button from '@rio-cloud/rio-uikit/Button';
import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setCurrentLayout } from '@/store/layout/layoutSlice';
import {
  gaPush,
  GET_STARTED_SECTION_ACTIONS,
  GET_STARTED_SECTION_CATEGORY,
} from '@/configuration/setup/googleAnalytics';
import { setCurrentPage } from '@/store/about/aboutSlice';

const texts = {
  description: 'getStartedPage.getStartedSection.description',
  learnMoreLabel: 'getStartedPage.getStartedSection.learnMore.label',
  titlePartOne: 'getStartedPage.getStartedSection.title.partOne',
  titlePartTwo: 'getStartedPage.getStartedSection.title.partTwo',
  startChatLabel: 'getStartedPage.common.startChat.label',
};

const GetStartedSection = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isDarkMode = useDarkMode();

  const handleReadMore = () => dispatch(setCurrentPage('page2'));
  const handleStartConversation = () => {
    dispatch(setCurrentLayout('chatHistory'));
    gaPush({
      category: GET_STARTED_SECTION_CATEGORY,
      action: GET_STARTED_SECTION_ACTIONS.CLICK_START_CHAT,
      label: 'User clicked start chat',
    });
  };

  return (
    <Container isDarkMode={isDarkMode}>
      <StyledImage
        src="assets/images/how-it-works.svg"
        alt={`${intl.formatMessage({ id: texts.titlePartOne })} ${intl.formatMessage({ id: texts.titlePartTwo })}`}
      />
      <Content>
        <h3 className="h3 text-uppercase">
          <FormattedMessage id={texts.titlePartOne} />
          <span className="h3 text-uppercase text-color-primary margin-left-5">
            <FormattedMessage id={texts.titlePartTwo} />
          </span>
        </h3>
        <p className="margin-bottom-20">
          <FormattedMessage id={texts.description} />
        </p>
        <ButtonContainer>
          <Button bsStyle={Button.PRIMARY} onClick={handleStartConversation}>
            <FormattedMessage id={texts.startChatLabel} />
          </Button>
          <Button bsStyle={Button.DEFAULT} onClick={handleReadMore} className="margin-left-5">
            <FormattedMessage id={texts.learnMoreLabel} />
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default GetStartedSection;

type ContainerProps = {
  isDarkMode: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 4rem 6rem;
  gap: 1rem;
  background: ${(props) => (!props.isDarkMode ? 'var(--color-white)' : 'var(--gray-darkest)')};
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isDarkMode ? 'var(--color-white)' : 'var(--gray-darkest)')};

  @media (max-width: 75rem) {
    flex-wrap: wrap;
    padding: 0;
  }
`;

const StyledImage = styled.img`
  max-width: 60%;

  @media (max-width: 48rem) {
    max-width: 100%;
  }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    max-width: 300px;
  h3 {
    text-transform: uppercase;
    text-align: right;
    margin-top: 10px !important;
    font-weight: bold;
    line-height: 1.1;
  }

  p {
    text-align: right;
  }
    
  @media (max-width: 125rem) {

    flex-direction: column;
    padding: 2rem;
    max-width:80%;
    
    p, h3 {
    text-align: center;
    line-height: 1.2;
    
    h3 {
    margin: 0 !important;
    }
    }
    @media (max-width: 48rem){
    padding: 0;
    h3, span {
    font-size: 2rem;
    }
    p {
    margin: 2rem 0 4rem 0;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  @media (max-width: 75rem) {
    justify-content: center;
    margin-bottom: 4rem;
  }
  @media (max-width: 48rem) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;
