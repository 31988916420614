import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { selectMessageById } from '@/store/chat/chat.slice';
import {
  resetStep,
  selectIsOnboardingMode,
  selectOnboardingStep,
  selectShowOnboardingTip,
  steps,
  showOnboardingTip,
  finishDemoFlow,
  selectIsDemoFlowEnabled,
  disableDemoFlow,
  selectOnboardingMessageId,
  selectIsOnboardingQuestionLoading,
  Steps,
} from '@/store/onboarding/onboardingSlice';
import { getLocale } from '@/configuration/lang/langSlice';
import MarkdownRenderers from '@/components/molecule/MarkdownRenderers/MarkdownRenderers';
import { onboardingMessagesDE, onboardingMessagesEN } from '../../../../mocks/mock.onboarding';
import OnboardingDraggableTip from '../../organism/OnboardingDraggableTip/OnboardingDraggableTip';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';

type BubbleContentProps = {
  messageId: number;
  isLoading: boolean;
  localThreadId: string | null;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  handleSend: (content: string, isOnboarding: boolean, nextStep?: Steps) => void;
};

const texts = {
  titles: {
    wellDone: 'chatPage.onboarding.wellDone',
    onboardingComplete: 'chatPage.onboarding.onboardingCompleteTitle',
  },
  contents: {
    tryNextQuestion: 'chatPage.onboarding.tryNextQuestion',
    onboardingComplete: 'chatPage.onboarding.onboardingCompleteContent',
  },
  buttons: {
    skip: 'chatPage.onboarding.buttonSkip',
    tryNextQuestion: 'chatPage.onboarding.buttonTryNextQuestion',
    okThanks: 'chatPage.onboarding.buttonOkThanks',
  },
  icons: {
    skipIcon: 'rioglyph-eye-close',
    sendIcon: 'rioglyph-send',
    repeat: 'rioglyph-repeat',
  },
  sendButton: 'chatPage.SpeechBubble.edit.button.send',
  cancelButton: 'chatPage.SpeechBubble.edit.button.cancel',
  roleLabels: {
    ai: 'chatPage.SpeechBubble.role.ai.label',
    you: 'chatPage.SpeechBubble.role.you.label',
  },
};

const BubbleContent = ({
  messageId,
  isEditing,
  setIsEditing,
  localThreadId,
  isLoading,
  handleSend,
}: BubbleContentProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { content, isOnboardingQuestion } = useAppSelector(selectMessageById(messageId));
  const isOnboardingQuestionLoading = useAppSelector(selectIsOnboardingQuestionLoading);
  const onboardingStep = useAppSelector(selectOnboardingStep);
  const isOnboardingMode = useAppSelector(selectIsOnboardingMode);
  const isOnboardingTipShow = useAppSelector(selectShowOnboardingTip);
  const isOnboardingDemoEnabled = useAppSelector(selectIsDemoFlowEnabled);
  const onboardingMessageId = useAppSelector(selectOnboardingMessageId);
  const currentLanguage = useAppSelector(getLocale);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState(content);
  const regex = /\bragDocuments\.[^\s]+/g;

  const questions = currentLanguage === 'de-DE' ? onboardingMessagesDE : onboardingMessagesEN;

  const getNextQuestion = (stepIndex: number) => {
    const nextStep = steps[stepIndex];
    const stepMessages = questions[nextStep]?.messages?.content;

    const humanQuestions = stepMessages?.filter((message) => message.role === 'human');
    return humanQuestions?.length > 0 ? humanQuestions[humanQuestions.length - 1].content : '';
  };

  const handleNextStep = () => {
    const currentStepIndex = onboardingStep !== 'step-0' ? steps.indexOf(onboardingStep) : -1;
    const nextStepIndex = currentStepIndex + 1 < steps.length ? currentStepIndex + 1 : 0;
    const nextStep = steps[nextStepIndex];

    dispatch({ type: 'onboarding/setStep', payload: nextStep });

    const nextQuestion = getNextQuestion(nextStepIndex);

    if (onboardingStep === 'step-4') {
      dispatch(resetStep());
    } else {
      handleSend(nextQuestion, true, nextStep);
    }
  };

  const handleOnHide = () => {
    if (onboardingStep === 'step-4') {
      dispatch(finishDemoFlow());
      dispatch(disableDemoFlow());
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleEditSubmit();
    }
  };

  const handleEditSubmit = () => {
    handleSend(message, false);
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isOnboardingQuestion && messageId) {
      dispatch(showOnboardingTip({ messageId }));
    }
  }, [isOnboardingQuestion, messageId, dispatch]);

  useEffect(() => {
    if (isEditing) {
      adjustTextareaHeight();
    }
  }, [isEditing, message]);

  return (
    <TextMessage>
      {isEditing ? (
        <div className="form-group width-100pct display-flex flex-column">
          <textarea
            ref={textareaRef}
            className="form-control"
            id="textArea"
            value={message}
            onKeyDown={handleKeyDown}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="display-flex gap-10 justify-content-end margin-top-10">
            <button className="btn btn-outline btn-secondary" onClick={handleEditCancel}>
              {intl.formatMessage({ id: texts.cancelButton })}
            </button>
            <button className="btn btn-primary" onClick={handleEditSubmit} disabled={isLoading}>
              {intl.formatMessage({ id: texts.sendButton })}
            </button>
          </div>
        </div>
      ) : null}
      <OnboardingDraggableTip
        show={
          !isLoading &&
          (isOnboardingQuestion ?? false) &&
          isOnboardingMode &&
          isOnboardingTipShow &&
          isOnboardingDemoEnabled &&
          messageId === onboardingMessageId &&
          !isOnboardingQuestionLoading &&
          localThreadId === 'onboarding-thread'
        }
        title={
          onboardingStep !== 'step-4'
            ? intl.formatMessage({ id: texts.titles.wellDone })
            : intl.formatMessage({ id: texts.titles.onboardingComplete })
        }
        content={
          onboardingStep !== 'step-4'
            ? intl.formatMessage({ id: texts.contents.tryNextQuestion })
            : intl.formatMessage({ id: texts.contents.onboardingComplete })
        }
        nextButton={{
          iconName: onboardingStep !== 'step-4' ? texts.icons.sendIcon : texts.icons.repeat,
          text:
            onboardingStep !== 'step-4'
              ? intl.formatMessage({ id: texts.buttons.tryNextQuestion })
              : intl.formatMessage({ id: texts.buttons.okThanks }),
          onClick: onboardingStep !== 'step-4' ? handleNextStep : handleOnHide,
        }}
      />
      <ReactMarkdown components={MarkdownRenderers(content, true)} remarkPlugins={[remarkGfm]}>
        {!isEditing ? content.replace(regex, match => intl.formatMessage({id: `chatPage.${match}`})) : undefined}
      </ReactMarkdown>
    </TextMessage>
  );
};

export default BubbleContent;

const TextMessage = styled.div`
  word-wrap: break-word;
`;
