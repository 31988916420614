import Button from '@rio-cloud/rio-uikit/Button';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ChatThreadCategory from '@/components/molecule/ChatThreadCategory/ChatThreadCategory';
import { getUserProfile } from '@/configuration/login/loginSlice';
import { useDeleteThreadsMutation, useFetchThreadsQuery } from '@/services/threadApi/threadApi';
import {
  selectCategorizedThreads,
  ChatThread,
  selectActiveThread,
  selectIsHistoryOfThreadsFetching,
  resetActiveThread,
  removeOnboardingThread,
} from '@/store/thread/threadSlice';
import { getUserId } from '@/utils/getUserId';
import SearchField from '@/components/atom/SearchField/SearchField';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import { closeSidebar } from '@/store/sidebar/sidebarSlice';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';
import { disableOnboardingMode } from '@/store/onboarding/onboardingSlice';

const texts = {
  addNewChat: 'chatPage.chatThreadList.threadDeletion.addNewChat.label',
  clearAll: 'chatPage.chatThreadList.clearAll.label',
  today: 'chatPage.chatThreadList.today.label',
  last7Days: 'chatPage.chatThreadList.last7Days.label',
  last30Days: 'chatPage.chatThreadList.last30Days.label',
  older: 'chatPage.chatThreadList.older.label',
  noMessage: 'chatPage.chatThreadList.error.noThreads',
  title: 'chatPage.chatThreadList.title',
  confirmDeletionTitle: 'chatPage.chatThreadList.confirmAllDeletion.title',
  confirmDeletionContent: 'chatPage.chatThreadList.confirmAllDeletion.content',
  threadsDeletionSuccess: 'chatPage.chatThreadList.threadsDeletion.success.notification',
  threadsDeletionError: 'chatPage.chatThreadList.threadsDeletion.error',
  cancel: 'common.cancel',
  confirm: 'common.confirm',
} as const;

type ChatHistoryListProps = {
  isLoading?: boolean;
};

const ChatHistoryList = ({ isLoading }: ChatHistoryListProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const user = useAppSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const threadId = useAppSelector(selectActiveThread)?.id;
  const threadsInitialList = useAppSelector(selectCategorizedThreads);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 47.5rem)' });
  const shouldSkip = Boolean(
    threadsInitialList.today?.length ||
      threadsInitialList.last7Days?.length ||
      threadsInitialList.last30Days?.length ||
      threadsInitialList.older?.length,
  );
  const { isFetching } = useFetchThreadsQuery({ userId, threadId }, { skip: shouldSkip || threadId === null });
  const isThreadsLoading = useAppSelector(selectIsHistoryOfThreadsFetching);
  const isLoadingSth = isThreadsLoading || isFetching;

  const [threadsList, setThreadsList] = useState(threadsInitialList);
  const [searchValue, setSearchValue] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [deleteThreads] = useDeleteThreadsMutation();

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteThreads({ userId }).unwrap();
      if (response.status === 'success') {
        Notification.success(intl.formatMessage({ id: texts.threadsDeletionSuccess }));
        dispatch(resetActiveThread());
      } else {
        Notification.error(intl.formatMessage({ id: texts.threadsDeletionError }));
      }
    } catch (error) {
      Notification.error(intl.formatMessage({ id: texts.threadsDeletionError }));
    } finally {
      handleConfirmationDialog();
    }
  };

  const hasValidThreads = (threads: ChatThread[]) => {
    return threads && threads.length > 0 && threads.some((thread) => thread.title && thread.title.trim() !== '');
  };
  const handleOnClick = () => {
    dispatch(resetActiveThread());
    dispatch(disableOnboardingMode());
    dispatch(removeOnboardingThread());
    if (isSmallScreen) {
      dispatch(closeSidebar());
    }
  };

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    const filteredLists = {
      today: threadsInitialList.today.filter((item) =>
        (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
      ),
      last7Days: threadsInitialList.last7Days.filter((item) =>
        (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
      ),
      last30Days: threadsInitialList.last30Days.filter((item) =>
        (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
      ),
      older: threadsInitialList.older.filter((item) =>
        (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
      ),
    };
    setThreadsList(filteredLists);
  };

  const handleConfirmationDialog = () => {
    document.querySelectorAll('.modal').forEach((modal) => {
      modal.classList.remove('show');
    });
    setShowDeleteDialog(false);
  };

  useEffect(() => {
    setThreadsList(threadsInitialList);
    handleSearch(searchValue);
  }, [threadsInitialList]);

  const deleteButtonClick = () => {
    document.querySelectorAll('.modal').forEach((modal) => {
      modal.classList.add('show');
    });
    setShowDeleteDialog(true);
  };

  return (
    <div>
      <div className="display-flex flex-column justify-content-start height-100pct">
        <div className="padding-bottom-20">
          <div className="padding-x-10 display-flex justify-content-start margin-top-20 margin-x-10">
            <Button
              className="width-100pct"
              bsStyle="primary"
              iconName="rioglyph rioglyph-plus-light"
              disabled={isLoading}
              onClick={handleOnClick}
            >
              <FormattedMessage id={texts.addNewChat} />
            </Button>
          </div>
          <div className="display-flex justify-content-between margin-top-20 margin-x-20">
            <p className="text-medium text-size-14">{intl.formatMessage({ id: texts.title })}</p>
            <Button
              bsStyle="default"
              className="margin-top-2"
              iconName="rioglyph rioglyph-trash"
              onClick={deleteButtonClick}
              variant="link"
            >
              <FormattedMessage id={texts.clearAll} />
            </Button>
            <ConfirmationDialog
              show={showDeleteDialog}
              title={intl.formatMessage({ id: texts.confirmDeletionTitle })}
              content={intl.formatMessage({ id: texts.confirmDeletionContent })}
              onClickConfirm={handleConfirmDelete}
              onClickCancel={handleConfirmationDialog}
              cancelButtonText={intl.formatMessage({ id: texts.cancel })}
              confirmButtonText={
                <>
                  <span className="rioglyph rioglyph-ok margin-right-5" />
                  <span>{intl.formatMessage({ id: texts.confirm })}</span>
                </>
              }
            />
          </div>
          <div className="margin-y-20">
            <SearchField onSearch={handleSearch} />
          </div>
          {isLoadingSth ? (
            <div className="padding-20">
              <Spinner />
            </div>
          ) : !threadsList || Object.values(threadsList).every((list) => list.length === 0) ? (
            <div className="padding-x-20 text-center">{intl.formatMessage({ id: texts.noMessage })}</div>
          ) : (
            <div>
              {hasValidThreads(threadsList.today) && (
                <ChatThreadCategory title={texts.today} threads={threadsList.today} searchWord={searchValue} />
              )}
              {hasValidThreads(threadsList.last7Days) && (
                <ChatThreadCategory title={texts.last7Days} threads={threadsList.last7Days} searchWord={searchValue} />
              )}
              {hasValidThreads(threadsList.last30Days) && (
                <ChatThreadCategory
                  title={texts.last30Days}
                  threads={threadsList.last30Days}
                  searchWord={searchValue}
                />
              )}
              {hasValidThreads(threadsList.older) && (
                <ChatThreadCategory title={texts.older} threads={threadsList.older} searchWord={searchValue} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatHistoryList;
