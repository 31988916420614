import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type FAQsItem = {
  id: string;
  name: string;
  icon: string;
};

export type FilterFAQsItem = {
  icon: string;
  values: FAQsItem[];
};

type FAQsState = FAQsItem[];

const initialState: FAQsState = [];

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    addQuestions: (state, action: PayloadAction<FAQsItem[]>) => {
      return [...state, ...action.payload];
    },
  },
});

const transformFaqItems = (items: FAQsItem[]): FilterFAQsItem[] => {
  return Object.values(
    items.reduce<Record<string, { icon: string; values: FAQsItem[] }>>((acc, item) => {
      const key = item.icon.toLowerCase();
      if (!acc[key]) {
        acc[key] = { icon: item.icon, values: [] };
      }
      acc[key].values.push({ id: item.id, icon: item.icon, name: item.name });
      return acc;
    }, {}),
  );
};

export const { addQuestions } = faqSlice.actions;

export const selectAllFAQs = (state: RootState) => state.faq;

export const filterAllFAQs = (state: RootState) => transformFaqItems(state.faq);

export default faqSlice.reducer;
