import jsPDF from 'jspdf';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import { visit } from 'unist-util-visit';
import remarkGfm from 'remark-gfm';
import autoTable from 'jspdf-autotable';

interface TableData {
  [key: string]: any;
}

const extractText = (node: any): string => {
  if (node.type === 'text') {
    return node.value;
  } else if (node.children && node.children.length > 0) {
    return node.children.map(extractText).join('');
  }
  return '';
};

export const exportResponseToPDF = (markdownContent: string) => {
  const doc = new jsPDF();
  let currentY = 15;
  const pageWidth = 180;

  const addText = (text: string, fontSize: number, lineHeight: number) => {
    doc.setFontSize(fontSize);
    const lines = doc.splitTextToSize(text, pageWidth);
    doc.text(lines, 10, currentY);
    currentY += lines.length * fontSize * lineHeight;
  };

  const ast = unified().use(remarkParse).use(remarkGfm).parse(markdownContent);

  visit(ast, (node, index, parent) => {
    switch (node.type) {
      case 'heading':
        const headingText = extractText(node);
        const headingFontSize = node.depth === 1 ? 14 : node.depth === 2 ? 12 : 11;
        const headingLineHeight = 0.6;
        addText(headingText, headingFontSize, headingLineHeight);
        currentY += 5;
        break;

      case 'list':
        node.children.forEach((listItem: any) => {
          let listItemText = '';

          listItem.children.forEach((listChild: any) => {
            if (listChild.type === 'text') {
              listItemText += listChild.value;
            } else {
              listItemText += extractText(listChild);
            }
          });

          const formattedText = `• ${listItemText.trim()}.`;
          addText(formattedText, 10, 0.75);
        });
        currentY += 5;
        break;
      case 'paragraph':
        if (parent && parent.type !== 'listItem') {
          if (node.children && node.children.length > 0) {
            const paragraphText = extractText(node);
            if (paragraphText.trim() !== '') {
              addText(paragraphText, 10, 0.75);
            }
          }
        }
        break;
      case 'code':
        if (node.lang && node.lang !== 'json') {
          break;
        }
        const jsonData = JSON.parse(node.value);

        if (jsonData.table === undefined) {
          break;
        }

        const data = jsonData.table;

        const headers: string[] = Array.isArray(data) ? Object.keys(data[0]) : Object.keys(data);
        const rows: string[][] = Array.isArray(data)
          ? data.map((row: TableData) => headers.map((header) => String(row[header])))
          : [];
        autoTable(doc, {
          head: [headers],
          body: rows,
          startY: currentY,
          theme: 'striped',
          headStyles: { fillColor: [48, 180, 192], textColor: [255, 255, 255] },
          margin: { top: 10, bottom: 10 },
          tableLineColor: [255, 255, 255],
        });
        currentY = doc.lastAutoTable.finalY + 10;
        break;

      default:
        break;
    }

    if (currentY > 275) {
      doc.addPage();
      currentY = 15;
    }
  });

  doc.save('response_FA.pdf');
};
