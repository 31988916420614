import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Message, Role } from '../../store/chat/chat.slice';
import { prepareHeaders } from '../../utils/prepareHeaders';
import { Response } from '../../utils/types';

type RouteRequest = {
  question: string;
  collection_name: string;
  language: string;
  threadId: string | null;
  userId: string;
  meta: {
    localId: string | null;
    isOnboarding: boolean;
    currentLanguage: string;
    nextStepIndex: string;
  };
};

type MessageResponseFromRoute = {
  message_id: string;
  last_updated: string;
  role: Role;
  content: string;
};

type RouteResponse = {
  messages: {
    role: Role;
    content: MessageResponseFromRoute[];
  };
  thread_id: string;
};

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${import.meta.env.VITE_BASE_BACKEND_URL}`, prepareHeaders }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation<{ data: Message[]; threadId: string | null }, Partial<RouteRequest>>({
      query: (message) => {
        const mappedMessage = {
          ...message,
          user_id: message.userId,
          thread_id: message.threadId && message.threadId.length > 0 ? message.threadId : null,
        };
        delete mappedMessage.userId;
        delete mappedMessage.threadId;
        return {
          url: '/v1/route/',
          method: 'POST',
          body: mappedMessage,
        };
      },
      refetchOnError: false,
      transformResponse: (response: Response<RouteResponse>) => {
        if (response.data && response.data.thread_id) {
          const updatedMessages = response.data.messages.content.map((msg) => ({
            ...msg,
            threadId: response.data?.thread_id,
            role: msg.role,
            id: msg.message_id,
            timestamp: msg.last_updated,
          }));

          return {
            ...response,
            data: updatedMessages,
            threadId: response.data?.thread_id,
          };
        }
        return response;
      },
    }),
  }),
});

export const { useSendMessageMutation } = chatApi;
