import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../utils/prepareHeaders';
import { Response } from '../../utils/types';

export const cookieApi = createApi({
    reducerPath: 'cookieApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${import.meta.env.VITE_DOCS_URI}/cookie`,
        prepareHeaders: (headers) => {
          prepareHeaders(headers);
          headers.set('Cache-Control', 'no-cache');
          headers.set('Expires', '-1');
          return headers;
        },
      }),
    endpoints: (builder) => ({
        fetchCookies: builder.query<Response, void>({
            query: () => '',
        }),
    }),
});

export const {
    useFetchCookiesQuery,
} = cookieApi;