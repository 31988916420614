import React, { Fragment, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import Select from '@rio-cloud/rio-uikit/Select';
import Divider from '@rio-cloud/rio-uikit/components/divider/Divider';

type CardDropdownProps = {
  headers: string[];
};
const CardDropdown = ({ children, headers }: PropsWithChildren<CardDropdownProps>) => {
  const [selectedCard, setSelectedCard] = useState(0);

  type Option = {
    id: string;
    label: string;
    selected: boolean;
  };

  const handleChange = (item: Option) => {
    setSelectedCard(Number(item.id));
  };

  type RowData = {
    header: string;
    value: React.ReactNode;
  };

  const getRowData = (card: React.ReactElement): RowData[] => {
    const cells = React.Children.toArray(card.props.children);
    return cells.map((cell, index) => ({
      header: headers[index],
      value: (cell as React.ReactElement).props.children,
    }));
  };

  const options = React.Children.toArray(children).map((card, index) => ({
    id: index.toString(),
    label: String(getRowData(card)[0].value),
    selected: index === 0,
  }));

  return (
    <div>
      <DropdownContainer>
        <label htmlFor="card-select">{headers[0]}:</label>
        {options ? <StyledSelect id="card-select" options={options} onChange={handleChange} /> : null}
      </DropdownContainer>
      <CardContainer>
        <Card>
          {React.Children.toArray(children).length > 0 &&
            getRowData(React.Children.toArray(children)[selectedCard]).map((row, rowIndex, array) => (
              <Fragment key={rowIndex}>
                <StyledRow>
                  <CardHeader>{row.header}</CardHeader>
                  <CardValue>{row.value}</CardValue>
                </StyledRow>
                {rowIndex < array.length - 1 && <Divider className="margin-0" spacing={5} />}
              </Fragment>
            ))}
        </Card>
      </CardContainer>
    </div>
  );
};

const DropdownContainer = styled.div`
  margin: 1rem 0;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 64rem) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.625rem;
  }
`;

const Card = styled.div`
  background: var(--color-white);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.375rem;
`;

const CardHeader = styled.div`
  color: var(--color-gray);
  font-weight: 600;
  font-size: 1.2rem;
  word-wrap: break-word;
  white-space: pre-wrap;
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
`;

const CardValue = styled.div`
  padding-left: 1rem;
  font-size: 1.2rem;
  white-space: pre-wrap;
  @media (max-width: 48rem) {
    font-size: 0.9rem;
  }
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 2px solid var(--color-gray);
  gap: 1rem;
`;

const StyledSelect = styled(Select)`
  .form-control {
  height: auto;
  }
  &.select .dropdown-toggle .selected-option-text, .multiselect .dropdown-toggle .selected-option-text {
  white-space: pre-wrap !important;
  }
  > ul > li > a {
  white-space: pre-wrap !important;
  }
  }
   @media (max-width: 48rem) {
   .form-control {
    font-size: 1rem  !important;
    }
    .dropdown-menu {
    font-size: 1rem  !important;
  }
  }
`;

export default CardDropdown;
