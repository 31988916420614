import { useIntl } from 'react-intl';
import { exportJsonTableToPDF } from '@/utils/export/exportJsonTableToPDF/exportJsonTableToPDF';
import { exportJsonTableToXLSX } from '@/utils/export/exportJsonTableToExcel/exportJsonTableToExcel';
import { gaPush, TABLE_ACTION_BAR_ACTIONS, TABLE_ACTION_BAR_CATEGORY } from '@/configuration/setup/googleAnalytics';

const classes = {
  container: 'display-flex justify-content-end align-items-center gap-5 margin-y-10',
  textMedium: 'text-medium text-size-10 text-size-14-sm',
  button: 'btn btn-primary btn-link',
  excelIcon: 'rioglyph rioglyph-csv',
  pdfIcon: 'rioglyph rioglyph-pdf-file',
};

const texts = {
  exportDataTable: 'chatPage.chatActions.table.export.dataTable',
  excel: 'Excel',
  pdf: 'PDF',
};

type TableActionBarProps = {
  message: string;
};

const TableActionBar = ({ message }: TableActionBarProps) => {
  const intl = useIntl();

  const handleDownload = (format: 'Excel' | 'PDF') => {
    if (format === 'Excel') {
      exportJsonTableToXLSX(message, 'data');
    } else if (format === 'PDF') {
      exportJsonTableToPDF(message, 'data');
    }

    gaPush({
      category: TABLE_ACTION_BAR_CATEGORY,
      action: format === 'Excel' ? TABLE_ACTION_BAR_ACTIONS.DOWNLOAD_EXCEL : TABLE_ACTION_BAR_ACTIONS.DOWNLOAD_PDF,
      label: `File Format: ${format}`,
    });
  };

  return (
    <div className={classes.container}>
      <span className={classes.textMedium}>{intl.formatMessage({ id: texts.exportDataTable })}</span>
      <span className={classes.container}>
        <button type="button" className={classes.button} onClick={() => handleDownload('Excel')}>
          <span className={classes.excelIcon} aria-hidden="true" />
          <span className="text-capitalize text-size-14">{texts.excel}</span>
        </button>
        <button type="button" className={classes.button} onClick={() => handleDownload('PDF')}>
          <span className={classes.pdfIcon} aria-hidden="true" />
          <span className="text-capitalize text-size-10 text-size-14">{texts.pdf}</span>
        </button>
      </span>
    </div>
  );
};

export default TableActionBar;
