import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { gaPush, PAGE_NAVIGATION_ACTIONS, PAGE_NAVIGATION_CATEGORY } from '@/configuration/setup/googleAnalytics';

type PageNavigationProps = {
  onNext: () => void;
  onPrevious: () => void;
  previousLabel: string;
  nextLabel: string;
};

const PageNavigation = ({ onNext, onPrevious, previousLabel, nextLabel }: PageNavigationProps) => {
  const handlePreviousClick = () => {
    gaPush({
      category: PAGE_NAVIGATION_CATEGORY,
      action: PAGE_NAVIGATION_ACTIONS.CLICK_PREVIOUS,
      label: `Prev: ${previousLabel}`,
    });
    onPrevious();
  };

  const handleNextClick = () => {
    gaPush({
      category: PAGE_NAVIGATION_CATEGORY,
      action: PAGE_NAVIGATION_ACTIONS.CLICK_NEXT,
      label: `Next: ${nextLabel}`,
    });
    onNext();
  };

  return (
    <NavigationContainer>
      <Pager onClick={handlePreviousClick}>
        <PagerContent alignRight>
          <PagerLabel>
            <FormattedMessage id="getStartedPage.pageNavigation.previous.label" />
          </PagerLabel>
          <PagerTitle alignRight>
            <FormattedMessage id={previousLabel} />
          </PagerTitle>
        </PagerContent>
        <Icon alignRight className="rioglyph rioglyph-arrow-left" />
      </Pager>
      <Pager onClick={handleNextClick}>
        <PagerContent>
          <PagerLabel>
            <FormattedMessage id="getStartedPage.pageNavigation.next.label" />
          </PagerLabel>
          <PagerTitle>
            <FormattedMessage id={nextLabel} />
          </PagerTitle>
        </PagerContent>
        <Icon className="rioglyph rioglyph-arrow-right" />
      </Pager>
    </NavigationContainer>
  );
};

export default PageNavigation;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 50px;
  width: 100%;
  @media (max-width: 37.5rem) {
    flex-direction: column;
  }
`;

const Pager = styled.div`
  background: var(--color-white);
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  padding: 1rem 1.25rem;
  width: 21.875rem;
`;

const PagerContent = styled.div<{ alignRight: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: ${(alignRight) => (alignRight ? 'flex-end' : 'flex-start')};
`;

const PagerLabel = styled.div`
  color: gray;
  line-height: 16px;
`;

const PagerTitle = styled.div<{ alignRight: boolean }>`
  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-align: ${(alignRight) => (alignRight ? 'right' : 'left')};
`;

const Icon = styled.span<{ alignRight: boolean }>`
  font-size: 200%;
  color: #333;
  margin: ${(alignRight) => (alignRight ? '0 10px 0 0' : '0 0 0 10px')};
`;
