import { Middleware } from '@reduxjs/toolkit';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';

const forbiddenMiddleware: Middleware = () => (next) => (action) => {
  if (action.payload?.status === 403) {
    Notification.error('You do not have permission to access this resource.', 'Error');
  }
  return next(action);
};

export default forbiddenMiddleware;
