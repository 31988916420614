import { accessToken } from '@/configuration/tokenHandling/accessToken';
import { AccessToken } from '@/configuration/tokenHandling/tokenSlice';
import { fetchEventSource } from '@microsoft/fetch-event-source';

type StatusEventResponse = {
  type: string | undefined;
  event_id: string;
  message: string;
  thread_id: string;
};

type FetchProcessingStatusProps = {
  userId: string;
  onMessage: (data: StatusEventResponse) => void;
  onOpen: (response: Response) => void;
  onError: (error: Error) => void;
  onClose: () => void;
};

export const fetchProcessingStatus = async ({ userId, onMessage, onOpen, onError, onClose }: FetchProcessingStatusProps) => {
  const url = `${import.meta.env.VITE_BASE_BACKEND_URL}/events/${userId}`;
  const controller = new AbortController();
  const token = accessToken.getAccessToken() as AccessToken;

  await fetchEventSource(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text/event-stream'
    },
    signal: controller.signal,
    async onopen(response) {
      if (response.ok) {
        onOpen(response);
      } else {
        onError(new Error('Failed to connect'));
      }
      return Promise.resolve();
    },
    onmessage(event) {
      const data: StatusEventResponse = JSON.parse(event.data);
      onMessage(data);
    },
    onerror(err) {
      console.error('Error fetching processing status:', err);
      onError(err);
    },
    onclose() {
      if (onClose) {
        onClose();
      }
    },
  });
};
