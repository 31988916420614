import { unified } from 'unified';
import remarkParse from 'remark-parse';
import { visit } from 'unist-util-visit';

export const extractTableDataFromJson = (markdownContent: string): string[][] => {
  const tableData: string[][] = [];

  const ast = unified().use(remarkParse).parse(markdownContent);

  visit(ast, 'code', (node: any) => {
    if (node.lang === 'json' && node.value) {
      try {
        const jsonData = JSON.parse(node.value);
        if (jsonData.table && Array.isArray(jsonData.table)) {
          const headers = Object.keys(jsonData.table[0]);
          const rows = jsonData.table.map((row: any) =>
            headers.map((header) => String(row[header]))
          );
          tableData.push(headers, ...rows);
        }
      } catch (error) {
        console.error('Failed to parse JSON:', error);
      }
    }
  });

  return tableData;
};