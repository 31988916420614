import { extractTableDataFromJson } from '@/utils/extractTableDataFromJson';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';


export const exportJsonTableToPDF = (markdownContent: string, fileName: string) => {
  const tableData = extractTableDataFromJson(markdownContent);

  if (tableData.length > 0) {
    const headers = tableData[0];
    const rows = tableData.slice(1);

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });

    const timestamp = new Date().toLocaleString();
    doc.setFontSize(8);
    doc.text(`Generated on: ${timestamp}`, 14, 10);

    autoTable(doc, {
      head: [headers],
      body: rows,
      startY: 20,
      headStyles: {
        fillColor: [48, 180, 192],
        textColor: [255, 255, 255],
        fontStyle: 'bold',
        fontSize: 7,
      },
      bodyStyles: {
        textColor: [0, 0, 0],
        fontSize: 7,
      },
      theme: 'striped',
      styles: {
        overflow: 'linebreak',
        fontSize: 7,
      },
      margin: { top: 20, right: 10, bottom: 10, left: 10 },
      pageBreak: 'auto',
      showHead: 'everyPage',
    });

    doc.save(`${fileName}.pdf`);
  }
};