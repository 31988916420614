import styled from 'styled-components';
import FeedbackForm from '@/components/organism/FeedbackForm/FeedbackForm';

const FeedbackPage = () => (
  <div className="container padding-y-25">
    <Container>
      <ContentWrapper>
        <FeedbackForm />
      </ContentWrapper>
    </Container>
  </div>
);

export default FeedbackPage;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 105rem;
  padding: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
`;
