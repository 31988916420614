import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { useMediaQuery } from 'react-responsive';
import {
  activateOnboardingMode,
  disableDemoFlow,
  disableOnboardingMode,
  enableDemoFlow,
  resetStep,
  selectIsDemoFlowFinish,
  selectIsOnboardingMode,
} from '@/store/onboarding/onboardingSlice';
import { addNewThread, removeOnboardingThread, resetActiveThread } from '@/store/thread/threadSlice';
import { closeSidebar, selectIsSidebarOpen } from '@/store/sidebar/sidebarSlice';
import { setShowTips } from '@/store/tips/tipsSlice';
import { setCurrentLayout } from '@/store/layout/layoutSlice';
import { setCurrentPage } from '@/store/about/aboutSlice';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import AnimatedRobot from '@/components/atom/AnimatedRobot/AnimatedRobot';
import AnimatedRobotSpeechBubble, {
  RobotMessage,
} from '@/components/molecule/AnimatedRobotSpeechBubble/AnimatedRobotSpeechBubble';

type Steps = {
  [key in number]: RobotMessage;
};

const texts = {
  stepOneTitle: 'chatPage.sidebar.onboardingMode.stepOne.title',
  stepOneText: 'chatPage.sidebar.onboardingMode.stepOne.text',
  stepOneButtonText: 'chatPage.sidebar.onboardingMode.stepOne.buttonText',
  stepOneAltButtonText: 'chatPage.sidebar.onboardingMode.stepOne.showTips.button',
  stepTwoTitle: 'chatPage.sidebar.onboardingMode.stepTwo.title',
  stepTwoText: 'chatPage.sidebar.onboardingMode.stepTwo.text',
  stepThreeTitle: 'chatPage.sidebar.onboardingMode.stepThree.title',
  stepThreeText: 'chatPage.sidebar.onboardingMode.stepThree.text',
  stepFourTitle: 'chatPage.sidebar.onboardingMode.stepFour.congrats.title',
  stepFourText: 'chatPage.sidebar.onboardingMode.stepFour.congrats.text',
  stepFourChatButton: 'chatPage.sidebar.onboardingMode.stepFour.chat.button',
  stepFourPromptButton: 'chatPage.sidebar.onboardingMode.stepFour.prompt.button',
  disableOnboarding: 'chatPage.sidebar.onboardingMode.disable',
  activateOnboarding: 'chatPage.sidebar.onboardingMode.activate',
  onboardingActiveLabel: 'chatPage.sidebar.onboardingMode.active.label',
  onboardingInactiveLabel: 'chatPage.sidebar.onboardingMode.inactive.label',
};

const OnboardingIntroductionContent = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isOnboardingMode = useAppSelector(selectIsOnboardingMode);
  const isSidebarOpen = useAppSelector(selectIsSidebarOpen);
  const isDemoFlowFinish = useAppSelector(selectIsDemoFlowFinish);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 47.5rem)' });

  const [messages, setMessages] = useState<RobotMessage[]>([]);
  const [isRobotVisible, setIsRobotVisible] = useState(false);
  const chatContainerRef = useRef(null);

  const addMessage = (newMessage: RobotMessage) => {
    setMessages((prev) => {
      const alreadyExists = prev.some((msg) => msg.title === newMessage.title);
      if (!alreadyExists) {
        return [...prev, newMessage];
      }
      return prev;
    });
  };

  const steps: Steps = {
    0: {
      title: texts.stepOneTitle,
      text: texts.stepOneText,
      buttons: [
        {
          text: texts.stepOneButtonText,
          onClick: () => {
            dispatch(activateOnboardingMode());
            dispatch(enableDemoFlow());
            dispatch(resetStep());
            addMessage(steps[1]);
            if (isSmallScreen) {
              dispatch(closeSidebar());
            }
          },
        },
        {
          text: texts.stepOneAltButtonText,
          onClick: () => {
            dispatch(setShowTips(true));
          },
        },
      ],
    },
    1: {
      title: texts.stepTwoTitle,
      text: texts.stepTwoText,
      buttons: [],
    },
    2: {
      title: texts.stepThreeTitle,
      text: texts.stepThreeText,
      buttons: [],
    },
    3: {
      title: texts.stepFourTitle,
      text: texts.stepFourText,
      buttons: [
        {
          text: texts.stepFourChatButton,
          onClick: () => {
            dispatch(disableDemoFlow());
            dispatch(removeOnboardingThread());
            dispatch(disableOnboardingMode());
            dispatch(addNewThread());
            dispatch(resetActiveThread());
            dispatch(setCurrentLayout('chatHistory'));
            if (isSmallScreen) {
              dispatch(closeSidebar());
            }
          },
        },
        {
          text: texts.stepFourPromptButton,
          onClick: () => {
            dispatch(setCurrentLayout('about'));
            dispatch(setCurrentPage('page4'));
          },
        },
      ],
    },
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (isSidebarOpen) {
      setTimeout(() => setIsRobotVisible(true), 700);

      setTimeout(() => {
        setMessages([steps[0]]);
      }, 1500);
    } else {
      setIsRobotVisible(false);
      setMessages([]);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (isDemoFlowFinish) {
      addMessage(steps[3]);
    }
  }, [isDemoFlowFinish]);

  return (
    <Container>
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip width="auto">
            {intl.formatMessage({ id: isOnboardingMode ? texts.disableOnboarding : texts.activateOnboarding })}
          </Tooltip>
        }
      >
        <StyledButton
          className={`btn btn-outline ${isOnboardingMode ? 'btn-primary' : 'btn-muted'}`}
          onClick={() => {
            if (isOnboardingMode) {
              dispatch(disableOnboardingMode());
              setMessages([steps[0]]);
            } else {
              dispatch(activateOnboardingMode());
              addMessage(steps[0]);
              addMessage(steps[1]);
            }
          }}
        />
      </OverlayTrigger>
      <div>
        <AnimatedRobotSpeechBubble messages={messages} />
        <AnimatedRobot isVisible={isRobotVisible} />
        <div ref={chatContainerRef} style={{ position: 'absolute', bottom: '0' }} />
      </div>
    </Container>
  );
};

export default OnboardingIntroductionContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 200px;
  height: 100%;
  width: fit-content;
  justify-content: flex-end;
  padding: 2rem;
`;

const StyledButton = styled.button`
  border: none !important;
`;
