import { Fragment, PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';

const LimitedList = ({ children }: PropsWithChildren) => {
  const intl = useIntl();
  const items = (Array.isArray(children) ? children : [children]).filter((item) => item !== '\n');
  const [showAll, setShowAll] = useState(items.length < 6);
  const shouldShowMoreButton = items.length > 5;

  const displayedItems = showAll ? items : items.slice(0, 3);

  return (
    <Fragment>
      <ul className="margin-top-0">{displayedItems}</ul>
      {shouldShowMoreButton && (
        <button type="button" onClick={() => setShowAll(!showAll)} className="btn btn-link padding-y-5">
          {intl.formatMessage({ id: showAll ? 'common.showLess.toggle' : 'common.showMore.toggle' })}
          <span className={`rioglyph rioglyph-chevron-${showAll ? 'up' : 'down'} margin-left-10`} />
        </button>
      )}
    </Fragment>
  );
};

export default LimitedList;
