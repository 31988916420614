import ListMenu from '@rio-cloud/rio-uikit/components/listMenu/ListMenu';
import { ListMenuItem } from '@rio-cloud/rio-uikit/components/listMenu/ListMenuGroup';
import { MouseEventHandler, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { selectCurrentPage, setCurrentPage } from '@/store/about/aboutSlice';
import { Page, pages, pageToContent } from '@/store/about/pageToContent';
import { gaPush, ABOUT_NAVIGATION_CATEGORY, ABOUT_NAVIGATION_ACTIONS } from '@/configuration/setup/googleAnalytics';
import { closeSidebar } from '@/store/sidebar/sidebarSlice';

const AboutNavigation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activePage = useSelector(selectCurrentPage);
  const title = intl.formatMessage({ id: 'common.gettingStarted.label' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 47.5rem)' });

  const handleItemClick = (page: Page) => () => {
    gaPush({
      category: ABOUT_NAVIGATION_CATEGORY,
      action: ABOUT_NAVIGATION_ACTIONS.CLICK_PAGE,
      label: `Clicked page: ${page}`,
    });
    dispatch(setCurrentPage(page));
    if (isSmallScreen) {
      dispatch(closeSidebar());
    }
  };

  const menuItems = (
    activeItem: Page,
    onItemClick: (page: Page) => MouseEventHandler<HTMLSpanElement>,
  ): ListMenuItem[] => [
    {
      group: title,
      navItems: pages.map((page) => ({
        key: page,
        item: (
          <span className={activeItem === page ? 'active' : ''} data-key={page} onClick={onItemClick(page)}>
            {intl.formatMessage({ id: pageToContent[page].translation })}
          </span>
        ),
      })),
    },
  ];

  useEffect(() => {
    if (isSmallScreen && activePage === 'page1') {
      dispatch(closeSidebar());
    }
  }, [activePage, dispatch]);

  return <ListMenu responsive={false} menuItems={menuItems(activePage, handleItemClick)} className="margin-top-20" />;
};

export default AboutNavigation;
