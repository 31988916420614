import * as xlsx from 'node-xlsx';
import * as FileSaver from 'file-saver';
import { extractTableDataFromJson } from '@/utils/extractTableDataFromJson';


export const exportJsonTableToXLSX = (markdownContent: string, fileName: string) => {
  const tableData = extractTableDataFromJson(markdownContent);

  if (tableData.length > 0) {
    const data = [
      {
        name: 'Data',
        data: tableData,
      },
    ];

    const buffer = xlsx.build(data);

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, `${fileName}.xlsx`);
  }
};
