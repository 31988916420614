import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useAppSelector } from '@/configuration/setup/hooks';
import { getLocale } from '@/configuration/lang/langSlice';

const texts = {
  title: 'getStartedPage.newFeaturesSection.title',
  description: 'getStartedPage.newFeaturesSection.description',
  howItWorksLabel: 'getStartedPage.howVirtualAssistantWorks.label',
  features: [
    'getStartedPage.newFeaturesSection.partOne',
    'getStartedPage.newFeaturesSection.partTwo',
    'getStartedPage.newFeaturesSection.partThree',
    'getStartedPage.newFeaturesSection.partFour',
    'getStartedPage.newFeaturesSection.partFive',
  ],
};

const NewFeaturesSection = () => {
  const isDarkMode = useDarkMode();
  const currentLanguage = useAppSelector(getLocale);

  return (
    <Container $isdarkmode={isDarkMode}>
      <TextContainer>
        <Heading $isdarkmode={isDarkMode}>
          <FormattedMessage id={texts.title} />
        </Heading>
        <Text $isdarkmode={isDarkMode}>
          <FormattedMessage id={texts.description} />
          <ul className="margin-top-5">
            {texts.features.map((feature, index) => (
              <li key={`feature-li-${index}`}>
                <div key={`feature-div-${index}`}>
                  <span key={`feature-span-${index}`}>
                    <FormattedMessage id={feature} key={`feature-${index}`} />
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </Text>
      </TextContainer>
      <ImageContainer>
        <img
          src={currentLanguage === 'de-DE' ? 'assets/images/screenshot_de.png' : 'assets/images/screenshot_en.png'}
          alt=""
          height={200}
        />
      </ImageContainer>
    </Container>
  );
};

export default NewFeaturesSection;

const Container = styled.div<{ $isdarkmode: boolean }>`
  display: flex;
  padding: min(4rem, 8%);
  background: ${($isdarkmode) => (!$isdarkmode ? 'var(--gray-darkest)' : 'var(--color-white)')};
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  gap: 3rem;
  border-radius: 8px;

  @media (max-width: 64rem) {
    flex-direction: column-reverse;
    align-items: start;
    flex-wrap: wrap;
    background: ${($isdarkmode) => ($isdarkmode ? 'var(--gray-darkest)' : 'var(--color-white)')};
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-right: 3rem;

  @media (max-width: 64rem) {
    padding: 0;
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const Heading = styled.h3<{ $isdarkmode: boolean }>`
  margin-bottom: 1rem;
  text-align: left;
  color: ${($isdarkmode) => ($isdarkmode ? 'var(--gray-darkest)' : 'var(--color-white)')};
  @media (max-width: 64rem) {
    color: var(--color-black);
  }
`;

const Text = styled.p<{ $isdarkmode: boolean }>`
  line-height: 1.5;
  max-width: 31.25rem;
  color: ${($isdarkmode) => ($isdarkmode ? 'var(--color-black)' : 'var(--color-white)')};
  @media (max-width: 64rem) {
    text-align: left;
    color: var(--color-black);
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: right;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 64rem) {
    text-align: center;
  }
`;
