import styled, { keyframes } from 'styled-components';
import robotImage from '/assets/images/robot.svg';

type AnimatedRobotProps = {
  isVisible: boolean;
};

const AnimatedRobot = ({ isVisible }: AnimatedRobotProps) => {
  return isVisible ? (
    <RobotContainer>
      <RobotImage src={robotImage} alt="Animated Robot" />
    </RobotContainer>
  ) : null;
};

export default AnimatedRobot;

const flyInAnimation = keyframes`
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const RobotContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  animation:
    ${flyInAnimation} 1s ease-out forwards,
    ${floatAnimation} 3s infinite ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
  bottom: 10px;
  left: 16px;
`;

const RobotImage = styled.img`
  width: 50px;
  height: auto;
  z-index: 1020;
`;
