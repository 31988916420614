import type { UserManager } from 'oidc-client-ts';
import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';

import { configureFetchDisplayMessages } from './lang/services';
import {
  configureMockUserManager,
  configureUserManager,
  createUserManager,
  type SessionRenewedResult,
} from './login/login';
import { accessToken } from './tokenHandling/accessToken';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { reportErrorToSentry } from './setup/sentry';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { config } from '../config';
import { reduxStore } from '../store/store';

export interface OAuthConfig {
  onSessionExpired: () => void;
  onSessionRenewed: () => void;
}

export const main = async (renderApp: () => void) => {
  const fetchDisplayMessages = configureFetchDisplayMessages(reduxStore);

  const oauthConfig = {
    onSessionExpired: () => {
      accessToken.discardAccessToken();
      reduxStore.dispatch(userSessionExpired());
    },
    onSessionRenewed: (result: SessionRenewedResult) => {
      trace('index.onTokenRenewed', result);

      accessToken.saveAccessToken(result.accessToken);
      reduxStore.dispatch(accessTokenStored(result.accessToken));
      reduxStore.dispatch(idTokenStored(result.idToken));
      reduxStore.dispatch(userProfileObtained(result.profile));

      reduxStore.dispatch(userSessionRenewed());

      // You will need to get the user language yourself then
      // you may fetch the suitable messages. Depending
      // on when and from where you fetch the user settings you might
      // want to employ a loading spinner while the request is ongoing.
      fetchDisplayMessages(result.locale);
    },
  } as OAuthConfig;

  const userManager: UserManager = config.login.mockAuthorization
    ? configureMockUserManager(oauthConfig)
    : configureUserManager(oauthConfig, createUserManager());

  const signinSilent = userManager.signinSilent.bind(userManager);
  document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => signinSilent());
  document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => signinSilent());

  try {
    await userManager.clearStaleState();
    await attemptInitialSignIn(userManager);
    renderApp();
  } catch (error) {
    trace('could not start application', error);
    reportErrorToSentry(error);
  }
};
