import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { nanoid } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUserProfile } from '@/configuration/login/loginSlice';
import { useSubmitFeedbackMutation } from '@/services/feedbackApi/feedback';
import { Feedback } from '@/store/feedback/feedbackSlice';
import { getUserId } from '@/utils/getUserId';
import StarRating from '@/components/molecule/StarRating/StarRating';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { SuccessMessage } from '../SuccessMessage/SuccessMessage';

const classes = {
  container: 'display-flex flex-column',
  title: 'margin-0',
  formGroup: 'form-group',
  formControl: 'form-control',
  button: 'btn btn-primary',
  buttonContainer: 'button-container',
};

const texts = {
  title: 'feedbackPage.feedbackForm.title',
  appeal: 'feedbackPage.feedbackForm.appeal',
  questionOneLabel: 'feedbackPage.feedbackForm.question_one.label',
  questionTwoLabel: 'feedbackPage.feedbackForm.question_two.label',
  additionalCommentLabel: 'feedbackPage.feedbackForm.additional_comment.label',
  commentPlaceholder: 'common.feedback.comment.placeholder',
  submittingLabel: 'feedbackPage.feedbackForm.submiting.label',
  submitLabel: 'common.feedback.submit.label',
  successNotification: 'common.feedback.success.notification',
  errorNotification: 'common.feedback.error.notification',
  validationEmptyError: 'common.feedback.validation.empty.error',
  validationTooLongError: 'common.feedback.validation.tooLong.error',
};

type FeedbackDataProps = {
  rate: number | 0;
  improvementSuggestions: string;
  additionalComments: string;
};

const FeedbackForm = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm<FeedbackDataProps>({ mode: 'onChange' });
  const intl = useIntl();

  const [submitFeedback, { isLoading }] = useSubmitFeedbackMutation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [formKey, setFormKey] = useState(0);
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');

  const handleSuccessDialogClose = () => setShowSuccessDialog(false);
  const handleErrorDialogClose = () => setShowErrorDialog(false);

  const validateInput = (value: string) => {
    const specialCharacterRegex = /^[^{}<>]*$/;
    if (!specialCharacterRegex.test(value)) {
      return intl.formatMessage({ id: texts.validationEmptyError });
    }
    if (value.trim().length > 2000) {
      return intl.formatMessage({ id: texts.validationTooLongError });
    }
    return true;
  };

  const onSubmit = async (data: FeedbackDataProps) => {
    try {
      const detail =
        data.improvementSuggestions || data.additionalComments
          ? `${data.improvementSuggestions} ${data.additionalComments}`
          : '';

      const feedbackData: Feedback = {
        id: nanoid(),
        rate: data.rate,
        detail,
        userId,
      };

      const response = await submitFeedback(feedbackData);

      if (response.data) {
        setShowSuccessDialog(true);
        reset();
        setFormKey((prevKey) => prevKey + 1);
      } else {
        setRequestError(`${intl.formatMessage({ id: texts.errorNotification })} ${response.error.error}`);
        setShowErrorDialog(true);
      }
    } catch (e) {
      setRequestError(e.message);
      setShowErrorDialog(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && isValid) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>
        <FormattedMessage id={texts.title} />
      </h3>
      <p>
        <FormattedMessage id={texts.appeal} />
      </p>
      <FeedbackContainer>
        <FormWrapper>
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
            <div className={classes.formGroup}>
              <label>
                <FormattedMessage id={texts.questionOneLabel} />
              </label>
              <Controller
                name="rate"
                control={control}
                key={formKey}
                rules={{ required: true }}
                render={({ field }) => <StarRating {...field} onChange={(rating) => field.onChange(rating)} />}
              />
            </div>

            <div className={classes.formGroup}>
              <label>
                <FormattedMessage id={texts.questionTwoLabel} />
              </label>
              <textarea
                className={classes.formControl}
                spellCheck="false"
                rows="6"
                placeholder={intl.formatMessage({ id: texts.commentPlaceholder })}
                {...register('improvementSuggestions', { validate: validateInput })}
                maxLength={2000}
              />
              {errors.improvementSuggestions && <ErrorText>{errors.improvementSuggestions.message}</ErrorText>}
            </div>

            <div className={classes.formGroup}>
              <label>
                <FormattedMessage id={texts.additionalCommentLabel} />
              </label>
              <textarea
                className={classes.formControl}
                spellCheck="false"
                rows="6"
                placeholder={intl.formatMessage({ id: texts.commentPlaceholder })}
                {...register('additionalComments', { validate: validateInput })}
                maxLength={2000}
              />
              {errors.additionalComments && <ErrorText>{errors.additionalComments.message}</ErrorText>}
            </div>

            <div className={classes.buttonContainer}>
              <button type="submit" disabled={isLoading || !isValid} className={classes.button}>
                <FormattedMessage id={isLoading ? texts.submittingLabel : texts.submitLabel} />
              </button>
            </div>
          </form>
        </FormWrapper>

        <ImageWrapper>
          <FeedbackImage src="assets/images/feedback.svg" alt="Feedback" />
        </ImageWrapper>

        {showSuccessDialog && (
          <SuccessMessage
            show={showSuccessDialog}
            handleClose={handleSuccessDialogClose}
            message={texts.successNotification}
          />
        )}
        {showErrorDialog && (
          <ErrorMessage show={showErrorDialog} handleClose={handleErrorDialogClose} message={requestError} />
        )}
      </FeedbackContainer>
    </div>
  );
};

export default FeedbackForm;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;

  @media (max-width: 64rem) {
    flex-direction: column;
    align-items: start;
  }
`;

const FormWrapper = styled.div`
  flex: 1;
  max-width: 31.25rem;
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 64rem) {
    display: none;
  }
`;

const FeedbackImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const ErrorText = styled.span`
  color: red;
  font-size: 0.875rem;
  display: block;
  margin-top: 5px;
`;
