import { FormattedMessage } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PageNavigation from '../PageNavigation/PageNavigation';
import { setCurrentPage } from '@/store/about/aboutSlice';
import { resetActiveThread } from '@/store/thread/threadSlice';
import { setCurrentLayout } from '@/store/layout/layoutSlice';
import {
  FREQUENTLY_ASKED_QUESTIONS_ACTIONS,
  FREQUENTLY_ASKED_QUESTIONS_CATEGORY,
  gaPush,
} from '@/configuration/setup/googleAnalytics';

const promptText = {
  introduction: 'getStartedPage.frequentlyAskedQuestions.introduction',
  aiBasicsHeading: 'getStartedPage.frequentlyAskedQuestions.aiBasics.title',
  aiBasicsQuestionsAnswers: [
    {
      question: 'getStartedPage.frequentlyAskedQuestions.aiBasics.questionOne',
      answer: 'getStartedPage.frequentlyAskedQuestions.aiBasics.answerOne',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.aiBasics.questionTwo',
      answer: 'getStartedPage.frequentlyAskedQuestions.aiBasics.answerTwo',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.aiBasics.questionThree',
      answer: 'getStartedPage.frequentlyAskedQuestions.aiBasics.answerThree',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.aiBasics.questionFour',
      answer: 'getStartedPage.frequentlyAskedQuestions.aiBasics.answerFour',
    },
  ],
  promptingHeading: 'getStartedPage.frequentlyAskedQuestions.prompting.title',
  promptingQuestionsAnswers: [
    {
      question: 'getStartedPage.frequentlyAskedQuestions.prompting.questionOne',
      answer: 'getStartedPage.frequentlyAskedQuestions.prompting.answerOne',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.prompting.questionTwo',
      answer: 'getStartedPage.frequentlyAskedQuestions.prompting.answerTwo',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.prompting.questionThree',
      answer: 'getStartedPage.frequentlyAskedQuestions.prompting.answerThree',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.prompting.questionFour',
      answer: 'getStartedPage.frequentlyAskedQuestions.prompting.answerFour',
    },
  ],
  dataProtectionAndProcessingHeading: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.title',
  dataProtectionAndProcessingQuestionsAnswers: [
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionOne',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerOne',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionTwo',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerTwo',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionThree',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerThree',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionFour',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerFour',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionFive',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerFive',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionSix',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerSix',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionSeven',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerSeven',
    },
  ],
};

const FrequentlyAskedQuestions = () => {
  const dispatch = useDispatch();
  const pageTopRef = useRef<HTMLDivElement>(null);

  const handlePreviousSection = () => dispatch(setCurrentPage('page3'));
  const handleGoToChat = () => {
    dispatch(resetActiveThread());
    dispatch(setCurrentLayout('chatHistory'));
    gaPush({
      category: FREQUENTLY_ASKED_QUESTIONS_CATEGORY,
      action: FREQUENTLY_ASKED_QUESTIONS_ACTIONS.CLICK_START_CHAT,
      label: 'User clicked start chat',
    });
  };

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const [activeTab, setActiveTab] = useState('aiBasics');

  const renderCards = (items: { question: string; answer: string }[]) => {
    return (
      <CardContainer>
        {items.map((item, index) => (
          <Card key={index}>
            <CardTitle className="text-color-primary">
              <FormattedMessage id={item.question} />
            </CardTitle>
            <CardContent>
              <FormattedMessage id={item.answer} />
            </CardContent>
          </Card>
        ))}
      </CardContainer>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'aiBasics':
        return renderCards(promptText.aiBasicsQuestionsAnswers);
      case 'prompting':
        return renderCards(promptText.promptingQuestionsAnswers);
      case 'dataProtection':
        return renderCards(promptText.dataProtectionAndProcessingQuestionsAnswers);
      default:
        return null;
    }
  };

  return (
    <div className="margin-top-25">
      <p className="margin-bottom-20">
        <FormattedMessage id={promptText.introduction} />
      </p>
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <NavPills>
        <li className={activeTab === 'aiBasics' ? 'active' : ''} onClick={() => setActiveTab('aiBasics')}>
          <FormattedMessage id={promptText.aiBasicsHeading} />
        </li>
        <li className={activeTab === 'prompting' ? 'active' : ''} onClick={() => setActiveTab('prompting')}>
          <FormattedMessage id={promptText.promptingHeading} />
        </li>
        <li className={activeTab === 'dataProtection' ? 'active' : ''} onClick={() => setActiveTab('dataProtection')}>
          <FormattedMessage id={promptText.dataProtectionAndProcessingHeading} />
        </li>
      </NavPills>
      <div className="panel panel-default panel-body bg-lighter panel-blank position-relative padding-0">
        <div>{renderTabContent()}</div>
      </div>
      <div className="panel panel-default panel-body bg-lighter panel-blank position-relative padding-0">
        <PageNavigation
          onNext={handleGoToChat}
          onPrevious={handlePreviousSection}
          previousLabel="getStartedPage.howVirtualAssistantWorks.label"
          nextLabel="getStartedPage.common.startChat.label"
        />
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 1.25rem;
  margin-top: 1.25rem;
  align-items: stretch;
  @media (max-width: 48rem) {
    display: flex;
    flex-direction: column;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 10px;
  line-height: 1.4;
  media (max-width: 48rem) {
    font-size: 1.5rem;
  }
`;

const CardContent = styled.p`
  font-size: 1.3rem;
  flex-grow: 1;
  media (max-width: 48rem) {
    font-size: 1.1rem;
  }
`;

const NavPills = styled.ul`
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  gap: 0.625rem;

  li {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 0.625rem 1rem;
    border-radius: 6px;
    transition:
      background 0.3s,
      color 0.3s;
    background: var(--gray-decent);

    &.active {
      background: var(--brand-primary);
      color: white;
      font-weight: bold;
    }
  }

  @media (max-width: 48rem) {
    flex-direction: column;
    li {
      width: 100%;
    }
  }
`;
