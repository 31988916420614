import { useIntl } from 'react-intl';
import Divider from '@rio-cloud/rio-uikit/components/divider/Divider';
import { Fragment } from 'react/jsx-runtime';
import { ChatThread } from '@/store/thread/threadSlice';
import ThreadListItem from '@/components/organism/ThreadListItem/ThreadListItem';
import styled from 'styled-components';

type ChatThreadCategoryProps = {
  threads: ChatThread[];
  title?: string;
  searchWord?: string;
};

const ChatThreadCategory = ({ title, threads, searchWord }: ChatThreadCategoryProps) => {
  const intl = useIntl();
  return (
    <div>
      {title ? (
        <Fragment>
          <Divider spacing={5} className="padding-0" />
          <StyledListItem>
            <ImgSrc className="rioglyph rioglyph-calendar" />
            <p className="text-medium">{intl.formatMessage({ id: title })}</p>
          </StyledListItem>
        </Fragment>
      ) : null}
      <Divider spacing={5} className="padding-0" />
      <ul className="overflow-auto list-group padding-0">
        {threads.map((thread, index) =>
          thread.title ? (
            <ThreadListItem
              index={index}
              key={`item-${thread.id}`}
              localId={thread.id}
              threadId={thread.threadId}
              firstMessage={thread.title}
              isFavorite={thread.isFavorite}
              searchWord={searchWord}
              testId={thread.testId}
            />
          ) : null,
        )}
      </ul>
    </div>
  );
};

const StyledListItem = styled.div`
  display: flex;
  width: 100%;
  gap: 0.6rem;
  align-items: start;
`;

const ImgSrc = styled.span`
  margin-top: 10px;
  margin-left: 15px;
  font-size: 16px;
  position: relative;
  top: 0.2rem;
`;

export default ChatThreadCategory;
